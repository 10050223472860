export const TopVault = ({ item, index }: any) => {
  console.log(item);
  const {
    FloorPrice,
    VaultName,
    VaultRoute,
    VaultSize,
    image,
    TotalStaked,
    TVL,
  } = item;
  const openVault = () => {
    window
      .open(`https://www.diamondvaults.io/vault/${VaultRoute}`, "_black")
      ?.focus();
  };
  return (
    <div className="w-full sm:w-full md:w-1/2 xl:w-1/3 px-6 py-0 sm:py-2  text-gray-600  cursor-pointer">
      <div
        onClick={openVault}
        className="w-full flex flex-row justify-between p-2 sm:p-4 px-3 transition-all border-b border-b-stone-300 hover:bg-white hover:border-b-0 hover:rounded-md hover:shadow-xl text-xs "
      >
        <div
          style={{ maxWidth: "62%" }}
          className="flex flex-row  justify-center align-middle items-center gap-2 overflow-hidden text-ellipsis"
        >
          <div className=" font-medium text-lg" style={{ width: "25px" }}>
            {index + 1}
          </div>
          {image ? (
            <img
              className="rounded-full w-10 h-10 object-contain bg-gray-900"
              alt=""
              src={image || ""}
            />
          ) : (
            <div className="rounded-full w-10 h-10 object-contain bg-gray-900"></div>
          )}
          <div className=" grid justify-center items-start align-middle">
            <div className=" font-bold text-black overflow-hidden text-ellipsis whitespace-nowrap">
              {VaultName}
            </div>
            <div className="flex flex-row  items-center">
              <img
                className="w-2 h-2 object-contain "
                src="https://cryptologos.cc/logos/solana-sol-logo.svg?v=022"
                alt="avatar"
              />
              &nbsp;
              <b>{(+FloorPrice.toFixed(3)).toLocaleString()}</b>
              &nbsp;Floor
            </div>
          </div>
        </div>
        {/* <div className=" flex flex-col justify-center items-start align-middle overflow-hidden text-ellipsis whitespace-nowrap">
          <div className="w-full flex flex-row justify-between overflow-hidden text-ellipsis whitespace-nowrap">
            Staked: <b>{TotalStaked.toLocaleString()}</b>
          </div>
          <div className="w-full flex flex-row items-center justify-between">
            MVL:&nbsp;
            <img
              className="w-2 h-2 object-contain "
              src="https://cryptologos.cc/logos/solana-sol-logo.svg?v=022"
              alt="avatar"
            />
            &nbsp;
            <b>{(+TVL.toFixed(3)).toLocaleString()} </b>
          </div>
        </div> */}
        <div className=" flex flex-col justify-center items-start align-middle overflow-hidden text-ellipsis whitespace-nowrap">
          <div className="w-full flex flex-row justify-end overflow-hidden text-ellipsis whitespace-nowrap">
            <b>{TotalStaked.toLocaleString()}</b>&nbsp;Staked
          </div>
          <div className="w-full flex flex-row items-center justify-between">
            <img
              className="w-2 h-2 object-contain "
              src="https://cryptologos.cc/logos/solana-sol-logo.svg?v=022"
              alt="avatar"
            />
            &nbsp;
            <b>{(+TVL.toFixed(3)).toLocaleString()}</b>
            &nbsp;Value
          </div>
        </div>
      </div>
    </div>
  );
};
