/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import { useFirebase } from "../../Auth/FirebaseService";

type SolanaHealthPayload = {
  solanaHealth?: {
    bannerMessage: string;
    disableToolbar: boolean;
    showAlertBanner: boolean;
    disableRaffles: boolean;
    bannarBGColor: string;
  };
  ClientRPC?: string;
};

const SolanaHealthContext = createContext<SolanaHealthPayload>({});

export const useSolanaHealth = () => useContext(SolanaHealthContext);

export const SolanaHealthProvider = ({ children }: any) => {
  const { firestore } = useFirebase();
  const [ClientRPC, setRPC] = useState<string>();
  const [solanaHealth, setSolanaHealth] = useState<any>();

  useEffect(() => {
    return firestore
      .doc("/AOverallStats/DiamondVaultConfig")
      .onSnapshot((snap) => {
        if (snap.exists) {
          const {
            ClientRPC,
            banner: { bannerMessage, bannarBGColor, showAlertBanner },
            disableToolbar,
            disableRaffles,
          } = snap.data()!;
          setRPC(ClientRPC);
          setSolanaHealth({
            bannerMessage,
            disableToolbar,
            bannarBGColor,
            showAlertBanner,
            disableRaffles,
          });
        }
      });
  }, []);

  const value: SolanaHealthPayload = {
    solanaHealth,
    ClientRPC,
  };
  return (
    <SolanaHealthContext.Provider value={value}>
      {ClientRPC && solanaHealth ? children : ""}
    </SolanaHealthContext.Provider>
  );
};
