import "./ProjectItem.scss";
import { Link } from "react-router-dom";
import { Statistic, Skeleton, Space } from "antd";
import firebase from "firebase";
import { useState } from "react";
import { BookBurnerStat } from "../../Hooks/useBookBurner";

type ProjectItemProps = {
  type: "carousel" | "grid";
  projectDoc?: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>;
};

const itemContainers = {
  carousel: "mx-4 flex align-middle items-center ",
  grid: "w-full sm:w-1/3 md:w-1/3 xl:w-1/5 p-4",
};

export const ProjectItem: React.FC<ProjectItemProps> = ({
  type,
  projectDoc,
}) => {
  const [{ projectName, coverImage, profileImage, vaultId }] = useState(
    projectDoc!.data()
  );

  return (
    <div className={`${itemContainers[type]} fade-in`}>
      <Link to={`/projects/${vaultId}`} className=" m-auto w-full">
        {/* <div className="mx-3 flex align-middle items-center profile-item-skeleton-container"> */}
        <div className="rounded-3xl w-full overflow-hidden shadow-xl m-auto max-w-xs my-3 bg-white">
          <div className="w-full h-40 object-cover bg-gray-300">
            <img
              alt=""
              src={coverImage?.url}
              className=" w-full h-full object-cover"
            />
          </div>

          <div className="flex justify-center -mt-8">
            <img
              alt=""
              src={profileImage?.url}
              className="rounded-full border-solid border-white border-2 -mt-3 bg-white w-20 h-20 object-cover"
            />
          </div>
          <div className="text-center px-3 pb-0 pt-2">
            <h2 className=" text-m bold ">{projectName}</h2>
          </div>
          <div className="text-center  pb-3">
            {/* <Statistic
              title="Book Burner"
              value={11.28}
              precision={2}
              valueStyle={{ color: "#3f8600", fontWeight: 800 }}
              prefix={
                <img
                  className="w-5 h-5 object-contain "
                  src="https://cryptologos.cc/logos/solana-sol-logo.svg?v=022"
                  alt="avatar"
                />
              }
            /> */}

            <div>
              <BookBurnerStat margin="auto" profile={projectDoc}></BookBurnerStat>
            </div>
          </div>
          {/* <div className="flex justify-center pb-0 ">
      <div className="text-center mr-3 border-r pr-3">
        <h2>34</h2>
        <span>Photos</span>
      </div>
      <div className="text-center">
        <h2>42</h2>
        <span>Friends</span>
      </div>
    </div> */}
        </div>
      </Link>
    </div>
  );
};

export const ProjectItemSkeleton = () => {
  return (
    <div className="mx-3 flex align-middle items-center profile-item-skeleton-container fade-in">
      <div className="rounded-3xl  overflow-hidden shadow-xl m-auto max-w-xs my-3 bg-white">
        <div className="w-64 h-40 object-cover bg-gray-300" />
        <div className="flex justify-center -mt-10">
          
          <Skeleton.Avatar
            style={{ width: "5rem", height: "5rem" }}
            active
            size={"large"}
          />
        </div>

        <div className="text-center px-3 pb-7 pt-3">
          <Space>
            <Skeleton.Input size="small" active />
          </Space>
        </div>
        <div className="text-center px-3 pb-6 pt-0">
          <Space>
            <Skeleton.Button active />
          </Space>
        </div>

        {/* <div className="flex justify-center pb-0 ">
          <div className="text-center mr-3 border-r pr-3">
            <h2>34</h2>
            <span>Photos</span>
          </div>
          <div className="text-center">
            <h2>42</h2>
            <span>Friends</span>
          </div>
        </div> */}
      </div>
    </div>
  );
};
