import { useState, useEffect, useRef } from "react";

export const useIntersection = (element: any, rootMargin: any) => {
  const [isVisible, setState] = useState(false);
  useRef();
  useEffect(() => {
    if (element) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setState(entry.isIntersecting);
        },
        { rootMargin }
      );

      element.current && observer.observe(element.current);
      return () => element?.current && observer.unobserve(element?.current);
    }
  }, [element]);

  return isVisible;
};

export const useIntersectionElement = (rootMargin: any) => {
  const [isVisible, setState] = useState(false);
  const ref = useRef<any>();
  useEffect(() => {
    if (ref.current) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setState(entry.isIntersecting);
        },
        { rootMargin }
      );

      ref.current && observer.observe(ref.current);
      return () => ref?.current && observer.unobserve(ref?.current);
    }
  }, [ref]);

  return [<div ref={ref}></div>, isVisible];
};
