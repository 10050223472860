import { useEffect, useState } from "react";
import { Button } from "react-daisyui";
import { useNavigate } from "react-router-dom";
import { useFirebase } from "../../../../Auth/FirebaseService";
import { TopVault } from "./TopVault/TopVault";
import "./TopVaults.scss";

export const TopVaults = () => {
  const navigate = useNavigate();

  const { firestore, user, loadingUserState } = useFirebase();
  const [setup, setSetup] = useState(false);
  const [leaderboard, setLeaderboard] = useState<any[]>();
  const [filtered, setFiltered] = useState<any>();
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    if (!setup) {
      console.log("in");
      getVaultsLeaderboard();
      setSetup(true);
    }
  }, [setup]);

  useEffect(() => {
    if (leaderboard) {
      if (search?.length > 0) {
        return setFiltered(
          [...leaderboard].filter(({ VaultName }: { VaultName: string }) => {
            return VaultName.toLocaleLowerCase().includes(
              search.toLocaleLowerCase()
            );
          })
        );
      }
      return setFiltered([...leaderboard]);
    }
    return setFiltered(undefined);
  }, [leaderboard, search]);

  const getVaultsLeaderboard = async () => {
    const doc = await firestore.doc(`/AOverallStats/AllVaultLeaderboard`).get();
    const { LeaderboardArray } = doc.data()!;
    setLeaderboard(
      LeaderboardArray.filter(({ TotalStaked }: any) => TotalStaked > 30)
        .sort((a: any, b: any) => b.FloorPrice - a.FloorPrice)
        .slice(0, 12)
    );
  };

  return (
    <div className=" flex flex-col justify-center items-center mt-40">
      <div className=" font-bold text-4xl">Top Vaults</div>
      <div className="container mx-auto mt-10">
        {leaderboard && (
          <div className="flex flex-wrap items-start">
            {leaderboard.map((item,index) => (
              <TopVault item={item} index={index} key={JSON.stringify(item)} />
            ))}
          </div>
        )}
      </div>
      <Button
        onClick={() => navigate("/vaults")}
        color="primary"
        className="text-white whitespace-nowrap  mt-10"
        style={{ color: "white" }}
      >
        EXPLORE VAULTS
      </Button>
    </div>
  );
};
