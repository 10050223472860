import { FC, useEffect, useState } from "react";
import "./RaffleResults.scss";
import firebase from "firebase";
import { useFirebase } from "../../../Auth/FirebaseService";
import { useRaffleStatus } from "../../../Hooks/useRaffleStatus";
import { Divider, Empty, List, Tag } from "antd";
import { Button } from "react-daisyui";
import moment from "moment";
type RaffleResultsProps = {
  raffleDoc:
    | firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
    | undefined;
};

export const RaffleResults: FC<RaffleResultsProps> = ({ raffleDoc }) => {
  const raffleStatus = useRaffleStatus(raffleDoc);

  const { firestore, user } = useFirebase();
  const [isSetup, setIsSetup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allTickets, setAllTickets] = useState<any>();
  const [allParticipants, setAllParticipants] = useState<any>();
  const [allWinners, setAllWinners] = useState<any>();
  const [myTickets, setMyTickets] = useState<any>();
  const raffleData = raffleDoc?.data();
  const {
    numOfTickets,
    token,
    ticketPrice,
    endDate,
    purchasedTickets,
    ownerWalletAddress,
    configWalletAddress,
    bookBurner,
    winners,
    metadata: { image, name },
  } = raffleData!;

  useEffect(() => {
    // if (activeKey === "2" && isSetup === false && loading === false) {
    setLoading(true);
    return raffleDoc?.ref.collection("ATicket").onSnapshot((docs) => {
      const myMap: any = {};
      setAllTickets(docs.docs);
      docs.docs
        .filter((doc) => doc.data().userId === user?.uid)
        .map((doc) => {
          const { walletAddress, numTickets } = doc.data();
          if (!myMap[walletAddress]) myMap[walletAddress] = 0;
          myMap[walletAddress] += numTickets;
        });
      const allMap: any = {};
      docs.docs
        .filter((doc) => doc.data().userId !== user?.uid)
        .map((doc) => {
          const { walletAddress, numTickets } = doc.data();
          if (!allMap[walletAddress]) allMap[walletAddress] = 0;
          allMap[walletAddress] += numTickets;
        });

      setMyTickets(
        Object.keys(myMap).map((key) => ({
          walletAddress: `${key.slice(0, 4)}...${key.slice(-4)}`,
          ticketNum: myMap[key],
        }))
      );
      setAllParticipants(
        Object.keys(allMap).map((key) => ({
          walletAddress: `${key.slice(0, 4)}...${key.slice(-4)}`,
          ticketNum: allMap[key],
        }))
      );

      setIsSetup(true);
      setLoading(false);
    });
    // }
  }, []);

  useEffect(() => {
    if (loading === false && raffleStatus === "ENDED") {
      const winnerMap: any = {};
      if (winners?.length > 0 && winners[0]?.status !== "no tickets sold") {
        for (let { userId, walletAddress } of winners) {
          allTickets
            .filter(
              (doc: any) =>
                doc.data().userId === userId &&
                doc.data().walletAddress === walletAddress
            )
            .map((doc: any) => {
              const { walletAddress, numTickets } = doc.data();
              if (!winnerMap[walletAddress]) winnerMap[walletAddress] = 0;
              winnerMap[walletAddress] += numTickets;
            });
        }
        setAllWinners(
          Object.keys(winnerMap).map((key) => ({
            walletAddress: `${key.slice(0, 4)}...${key.slice(-4)}`,
            ticketNum: winnerMap[key],
          }))
        );
      }
    }
  }, [raffleStatus, loading]);

  const hasEnded = () => raffleStatus === "DRAWING" || raffleStatus === "ENDED";
  if (!raffleDoc || !raffleData || !hasEnded()) return <></>;

  const hasWinners = () => winners?.length > 0 && typeof winners[0] != "string";

  return (
    <div className=" h-96 overflow-scroll participants-container">
      {allWinners && (
        <List
          itemLayout="horizontal"
          dataSource={allWinners}
          loading={allWinners === undefined}
          header={<b>WINNERS</b>}
          locale={{
            emptyText: (
              <Empty
                description="You have no raffle tickets"
                imageStyle={{
                  height: 60,
                  margin: "auto",
                  width: "90px",
                }}
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              ></Empty>
            ),
          }}
          renderItem={({ walletAddress, ticketNum }: any) => (
            <List.Item actions={[<div>{ticketNum} Tickets</div>]}>
              <List.Item.Meta title={<div>{walletAddress}</div>} />
            </List.Item>
          )}
        />
      )}
      <List
        itemLayout="horizontal"
        dataSource={myTickets}
        loading={myTickets === undefined}
        header={<b>YOUR ENTRIES</b>}
        locale={{
          emptyText: (
            <Empty
              description="You have no raffles ticktes"
              imageStyle={{
                height: 60,
                margin: "auto",
                width: "90px",
              }}
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            ></Empty>
          ),
        }}
        renderItem={({ walletAddress, ticketNum }: any) => (
          <List.Item actions={[<div>{ticketNum} Tickets</div>]}>
            <List.Item.Meta title={<div>{walletAddress}</div>} />
          </List.Item>
        )}
      />

      <List
        itemLayout="horizontal"
        dataSource={allParticipants}
        header={<b>ALL</b>}
        loading={allParticipants === undefined}
        locale={{
          emptyText: (
            <Empty
              description="No one has entered this raffle yet"
              imageStyle={{
                height: 60,
                margin: "auto",
                width: "90px",
              }}
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            ></Empty>
          ),
        }}
        renderItem={({ walletAddress, ticketNum }: any) => (
          <List.Item actions={[<div>{ticketNum} Tickets</div>]}>
            <List.Item.Meta title={<div>{walletAddress}</div>} />
          </List.Item>
        )}
      />
    </div>
  );
};
