import "./Slogen.scss";

export const Slogen = () => {
  return (
    <div className="slogen-main-container">
      <div className="small-container">
        <div className="small-inner">
          <div className="blur-1">UTILITY</div>
          <div className="normal">STAKING</div>
          <div className="blur-2">RAFFLES</div>
        </div>
      </div>
      <div className="main-text">
        FOR
        <br />
        EVERYONE
      </div>
    </div>
  );
};
