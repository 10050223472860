import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import { LandingPage } from "../Pages/LandingPage/LandingPage";
import { ExploreRafflesPage } from "../Pages/ExploreRafflesPage/ExploreRafflesPage";
import { ExploreProjectsPage } from "../Pages/ExploreProjectsPage/ExploreProjectsPage";
import { ProjectProfilePage } from "../Pages/ProjectProfilePage/ProjectProfilePage";
import { ProfilePage } from "../Pages/ProfilePage/ProfilePage";
import { VaultsPage } from "../Pages/Vaults/Vaults";
import { VaultRedirect } from "./VaultRedirect";

export const Routing = ({ history }: any) => {
  useEffect(() => {
    if (history) {
      const unlisten = history.listen(() => {
        window.scrollTo(0, 0);
      });
      return () => {
        unlisten();
      };
    }
  }, []);

  return (
    <div
      onScrollCapture={() => {
        (window as any).scrollToTOP();
      }}
    >
      <Routes>
        <Route
          path="/"
          element={
            <EnterPage>
              <LandingPage />
            </EnterPage>
          }
        />
        <Route
          path="/verify"
          element={
            <EnterPage>
              <LandingPage />
            </EnterPage>
          }
        />
        <Route
          path="profile"
          element={
            <EnterPage>
              <ProfilePage />
            </EnterPage>
          }
        />
        <Route
          path="raffles"
          element={
            <EnterPage>
              <ExploreRafflesPage />
            </EnterPage>
          }
        />
        <Route
          path="/projects"
          element={
            <EnterPage>
              <ExploreProjectsPage />
            </EnterPage>
          }
        />
        <Route
          path="/projects/:id"
          element={
            <EnterPage>
              <ProjectProfilePage />
            </EnterPage>
          }
        />
        <Route
          path="/vaults"
          element={
            <EnterPage>
              <VaultsPage />
            </EnterPage>
          }
        />
        <Route
          path="/vault"
          element={
            <EnterPage>
              <VaultRedirect />
            </EnterPage>
          }
        />
        <Route
          path="/vault/:id"
          element={
            <EnterPage>
              <VaultRedirect />
            </EnterPage>
          }
        />
        {/* <Route path="*" element={<Navigate to="/" />} /> */}
      </Routes>
    </div>
  );
};

const EnterPage = ({ children }: any) => {
  return <div style={{ animation: "fadeIn 1s" }}>{children}</div>;
};
