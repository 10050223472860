import { Avatar, Empty, List } from "antd";
import { Range, Pagination, Stats, Form, Button } from "react-daisyui";
import firebase from "firebase";
import "./RaffleParticipants.scss";
import { useEffect, useState } from "react";
import { useFirebase } from "../../../Auth/FirebaseService";

type RaffleParticipantsProps = {
  raffleDoc:
    | firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
    | undefined;
  activeKey: string;
  enterRaffle: () => void;
};

export const RaffleParticipants: React.FC<RaffleParticipantsProps> = ({
  enterRaffle,
  activeKey,
  raffleDoc,
}) => {
  const { firestore, user } = useFirebase();
  const [isSetup, setIsSetup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allParticipants, setAllParticipants] = useState<any>();
  const [myTickets, setMyTickets] = useState<any>();

  useEffect(() => {
    // if (activeKey === "2" && isSetup === false && loading === false) {
    setLoading(true);
    return raffleDoc?.ref.collection("ATicket").onSnapshot((docs) => {
      const myMap: any = {};
      docs.docs
        .filter((doc) => doc.data().userId === user?.uid)
        .map((doc) => {
          const { walletAddress, numTickets } = doc.data();
          if (!myMap[walletAddress]) myMap[walletAddress] = 0;
          myMap[walletAddress] += numTickets;
        });
      const allMap: any = {};
      docs.docs
        .filter((doc) => doc.data().userId !== user?.uid)
        .map((doc) => {
          const { walletAddress, numTickets } = doc.data();
          if (!allMap[walletAddress]) allMap[walletAddress] = 0;
          allMap[walletAddress] += numTickets;
        });

      setMyTickets(
        Object.keys(myMap).map((key) => ({
          walletAddress: `${key.slice(0, 4)}...${key.slice(-4)}`,
          ticketNum: myMap[key],
        }))
      );
      setAllParticipants(
        Object.keys(allMap).map((key) => ({
          walletAddress: `${key.slice(0, 4)}...${key.slice(-4)}`,
          ticketNum: allMap[key],
        }))
      );
      setIsSetup(true);
      setLoading(false);
    });
    // }
  }, []);

  return (
    <div className=" h-96 overflow-scroll participants-container">
      {/* {myTickets?.length === 0 && (
        
      )} */}
      <List
        itemLayout="horizontal"
        dataSource={myTickets}
        loading={myTickets === undefined}
        header={<b>YOUR ENTRIES</b>}
        locale={{
          emptyText: (
            <Empty
              description="You have no raffle tickets"
              imageStyle={{
                height: 60,
                margin: "auto",
                width: "90px",
              }}
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            >
              <Button onClick={enterRaffle}>Enter raffle</Button>
            </Empty>
          ),
        }}
        renderItem={({ walletAddress, ticketNum }: any) => (
          <List.Item actions={[<div>{ticketNum} Tickets</div>]}>
            <List.Item.Meta title={<div>{walletAddress}</div>} />
          </List.Item>
        )}
      />
      <List
        itemLayout="horizontal"
        dataSource={allParticipants}
        header={<b>ALL</b>}
        loading={allParticipants === undefined}
        locale={{
          emptyText: (
            <Empty
              description="No one has entered this raffle yet"
              imageStyle={{
                height: 60,
                margin: "auto",
                width: "90px",
              }}
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            ></Empty>
          ),
        }}
        renderItem={({ walletAddress, ticketNum }: any) => (
          <List.Item actions={[<div>{ticketNum} Tickets</div>]}>
            <List.Item.Meta title={<div>{walletAddress}</div>} />
          </List.Item>
        )}
      />
    </div>
  );
};
