import { Popconfirm, Spin, Tabs } from "antd";
import { useEffect, useState } from "react";
import { useFirebase } from "../../Auth/FirebaseService";
import "./ProfilePage.scss";
import firebase from "firebase";
import { GridLayout } from "../../Layout/GridLayout";
import {
  RaffleItem,
  RaffleItemSkeleton,
} from "../../Compontents/RaffleItem/RaffleItem";
import { useNavigate, useParams } from "react-router-dom";
import { Footer } from "../../Layout/Footer/Footer";
import { Button } from "react-daisyui";
import { useSignin } from "../../Auth/SigninService/SigninService";

const { TabPane } = Tabs;

export const ProfilePage = () => {
  const { firestore, user, loadingUserState, auth } = useFirebase();
  const [setup, setSetup] = useState(false);
  const params = useParams();
  const { loadingUserDoc, userDoc } = useSignin();
  const navigate = useNavigate();
  const [rafflesIds, setRafflesIds] = useState<string[]>();
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    console.log([setup, user, loadingUserState]);

    if (!setup) {
      if (!user && !loadingUserState) return navigate("/");
      if (user && !loadingUserState) {
        console.log("in");

        getProjectProfile();
        getEndingSoonRaffles();
        setSetup(true);
      }
    }
  }, [setup, user, loadingUserState]);

  const logout = async () => {
    setConfirmLoading(true);
    await auth.signOut();
    setVisible(false);
    setConfirmLoading(false);

    navigate("/");
  };

  const showPopconfirm = () => {
    setVisible(true);
  };

  const getProjectProfile = () => {
    // alert(params["id"]);
  };

  const getEndingSoonRaffles = async () => {
    if (user) {
      firestore
        .collectionGroup(`ATicket`)
        .where("userId", "==", user?.uid)
        .orderBy("createDate", "desc")
        .get()
        .then(({ docs }) => {
          const uniqueIds = docs
            .map((doc) => {
              if (typeof doc.data().raffle === "string")
                return firestore.doc(doc.data().raffle).id;
              return doc.data().raffle.id;
            })
            .filter((v, i, a) => a.indexOf(v) === i);
          console.log(uniqueIds);

          setRafflesIds(uniqueIds);
        })
        .catch((err) => {
          console.error("ge}tRecentlyEndingSoonRaffles", err);
        });
    }
  };

  if (loadingUserDoc || (!loadingUserDoc && !userDoc)) {
    return <Spin />;
  }

  const { discordInfo, twitterInfo, displayName, UserId } = userDoc.data();
  return (
    <>
      <div className=" relative">
        <div
          className="text-gray-600 body-font overflow-hidden relative "
          style={{ height: "calc(100vh - 70px)", overflow: "auto" }}
        >
          <main className=" bg-opacity-25">
            <div className=" lg:w-8/12 lg:mx-auto mb-8">
              <header className="flex flex-wrap items-center p-4 md:py-8">
                <img
                  className="  hidden md:block w-40 h-40 md:w-40 md:h-40 object-contain bg-white rounded-full 
                   p-1"
                  src={twitterInfo.profile_image_url_https.replace(
                    "_normal",
                    ""
                  )}
                  alt="profile"
                />

                <div className="w-8/12 md:w-7/12 ml-4  hidden md:block">
                  <h2 className="text-3xl inline-block font-light md:mr-2 mb-2 sm:mb-0">
                    {displayName}
                  </h2>

                  <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                    <i className="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"></i>
                    @{twitterInfo.screen_name}
                  </div>
                  <div className="mb-2 text-blueGray-600 ">
                    <i className="fas fa-briefcase mr-2 text-lg text-blueGray-400"></i>
                    DISCORD: <b>{discordInfo.username}</b>
                  </div>
                  <div className="mb-2 text-blueGray-600">
                    <i className="fas fa-university mr-2 text-lg text-blueGray-400"></i>
                    DV User ID: <b>{userDoc.id}</b>
                  </div>
                  <Popconfirm
                    title="Are you sure you want to logout?"
                    visible={visible}
                    onConfirm={logout}
                    okButtonProps={{ loading: confirmLoading }}
                    onCancel={() => {
                      setVisible(false);
                      setConfirmLoading(false);
                    }}
                  >
                    <Button
                      onClick={showPopconfirm}
                      color="error"
                      size="xs"
                      className="mt-5"
                      variant="outline"
                      // style={{ color: "red" }}
                    >
                      Logout
                    </Button>
                  </Popconfirm>
                </div>

                <div className="md:hidden my-2 w-full text-center">
                  <img
                    className="w-48 h-48 m-auto object-contain bg-white rounded-full
                   p-1"
                    src={twitterInfo.profile_image_url_https.replace(
                      "_normal",
                      ""
                    )}
                    alt="profile"
                  />
                  <div className="my-5 text-lg">{displayName}</div>
                  <div className="text-sm leading-normal mt-0  text-blueGray-400 font-bold uppercase">
                    <i className="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"></i>
                    @{twitterInfo.screen_name}
                  </div>
                  <div className="mb-2 text-blueGray-600 mt-0">
                    <i className="fas fa-briefcase mr-2 text-lg text-blueGray-400"></i>
                    DISCORD: <b>{discordInfo.username}</b>
                  </div>
                  <div className="mb-2 text-blueGray-600">
                    <i className="fas fa-university mr-2 text-lg text-blueGray-400"></i>
                    DV User ID: <b>{userDoc.id}</b>
                  </div>
                  <Popconfirm
                    title="Are you sure you want to logout?"
                    visible={visible}
                    onConfirm={logout}
                    okButtonProps={{ loading: confirmLoading }}
                    onCancel={() => {
                      setVisible(false);
                      setConfirmLoading(false);
                    }}
                  >
                    <Button
                      onClick={showPopconfirm}
                      color="error"
                      size="xs"
                      className="mt-5"
                      variant="outline"
                      // style={{ color: "red" }}
                    >
                      Logout
                    </Button>
                  </Popconfirm>
                
                </div>
              </header>
            </div>
          </main>
          <div className="container mx-auto min-h-full">
            <Tabs
              className="block"
              defaultActiveKey="1"
              type="line"
              centered
              size={"large"}
              style={{ marginBottom: 32 }}
            >
              <TabPane tab="My Raffles" key="1">
                <GridLayout>
                  {rafflesIds?.map((rafflesId) => (
                    <RaffleItem
                      key={rafflesId}
                      raffleId={rafflesId}
                      layoutType="grid"
                    />
                  ))}
                  {!rafflesIds &&
                    [1].map(() => {
                      return <RaffleItemSkeleton layoutType="grid" />;
                    })}
                </GridLayout>
              </TabPane>
            </Tabs>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};
