import axios from "axios";
type BuyRaffleTicketsPayload = {
  walletAddress: string;
  numTickets: number;
  bookBurner: boolean;
  trans: any;
};

export const buyRaffleTickets = (
  userToken: string,
  raffleId: string,
  payload: BuyRaffleTicketsPayload
) =>
  new Promise<any>((resolve: any) => {
    const data = JSON.stringify({
      data: payload,
    });
    const config = {
      method: "post",
      url: `https://us-central1-nft-anybodies.cloudfunctions.net/raffles/api/v1/tickets/${raffleId}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.log(error);
        resolve(undefined);
      });
  });

export const getUserRoleInServer = (userToken: string, raffleId: string) =>
  new Promise<any>((resolve: any) => {
    const config = {
      method: "get",
      url: `https://us-central1-nft-anybodies.cloudfunctions.net/raffles/api/v1/roles/${raffleId}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.log(error);
        resolve(undefined);
      });
  });
