import "./ProjectProfilePage.scss";
import React, { useEffect, useState } from "react";
import { RadioChangeEvent, Skeleton, Space } from "antd";
import { Button, Descriptions, PageHeader, Statistic, Tabs } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Card, Col, Row } from "antd";
import { ExploreRafflesPage } from "../ExploreRafflesPage/ExploreRafflesPage";
import { useNavigate, useParams } from "react-router-dom";
import { useFirebase } from "../../Auth/FirebaseService";
import firebase from "firebase";
import { GridLayout } from "../../Layout/GridLayout";
import {
  RaffleItem,
  RaffleItemSkeleton,
} from "../../Compontents/RaffleItem/RaffleItem";
import { BookBurnerStat, useBookBurner } from "../../Hooks/useBookBurner";
const { TabPane } = Tabs;

export const ProjectProfilePage = () => {
  const { firestore } = useFirebase();
  const [setup, setSetup] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  const [raffles, setRaffles] =
    useState<
      firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]
    >();
  const [profile, setProfile] =
    useState<
      firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
    >();
  const [bookBurnerLoaded, bookBurnerSol] = useBookBurner(profile);

  useEffect(() => {
    setSetup(true);
    getProjectProfile();
    getEndingSoonRaffles();
  }, [setup, params]);

  const getProjectProfile = async () => {
    if (params["id"]) {
      if (params["id"] !== profile?.data()?.vaultId) {
        setRaffles(undefined);
        setProfile(undefined);
        const profile = await firestore
          .collectionGroup("Profile")
          .where("vaultId", "==", params["id"])
          .get();
        if (!profile.empty) {
          return setProfile(profile.docs[0]);
        }
      } else {
        return;
      }
    }
    return navigate("/projects");
  };
  const getEndingSoonRaffles = async () => {
    firestore
      .collection(`/ARaffle`)
      .where("vaultId", "==", params["id"])
      .where("creatorType", "==", "project")
      .where("status", "in", ["running", "drawing", "ended"])
      .orderBy("endDate", "desc")
      .get()
      .then(({ docs }) => {
        setRaffles(docs);
      })
      .catch((err) => {
        console.error("getRecentlyEndingSoonRaffles", err);
      });
  };

  const profileData = profile?.data();
  if (!profile || !profileData) return <ProjectProfilePageSkeleton />;
  const { profileImage, projectName } = profileData;
  return (
    <>
      <div
        className="text-gray-600 body-font overflow-hidden relative fade-in"
        style={{ height: "calc(100vh - 70px)", overflow: "auto" }}
      >
        <main className=" bg-opacity-25">
          <div className=" lg:w-8/12 lg:mx-auto mb-8">
            <header className="flex flex-wrap items-center p-4 md:py-8">
              <div className="md:w-3/12 md:ml-16  hidden md:block">
                <img
                  className="w-20 h-20 md:w-40 md:h-40 object-contain bg-white rounded-full
                   p-1"
                  src={profileImage?.url}
                  alt="profile"
                />
              </div>

              <div className="w-8/12 md:w-7/12 ml-4  hidden md:block">
                <div className="md:flex md:flex-wrap md:items-center mb-4">
                  <h2 className="text-3xl inline-block font-light md:mr-2 mb-2 sm:mb-0">
                    {projectName}
                  </h2>
                </div>

                <div className="hidden md:block">
                  <div className="flex flex-row align-middle items-center gap-5 w-full content-center justify-left pb-5 mt-2">
                    {SocialLinks.filter(
                      ({ switchKey, linkKey }) =>
                        profileData[switchKey] &&
                        profileData[linkKey]?.length > 0
                    ).map(({ switchKey, linkKey, icon }) => (
                      <div
                        key={switchKey}
                        onClick={() =>
                          window.open(profileData[linkKey], "_blank")?.focus()
                        }
                      >
                        {icon}
                      </div>
                    ))}
                  </div>
                  <BookBurnerStat margin="" profile={profile}></BookBurnerStat>

                  {/* <Statistic
                    title="Book Burner"
                    value={bookBurnerSol as number}
                    loading={!bookBurnerLoaded as boolean}
                    precision={2}
                    valueStyle={{ color: "#3f8600" }}
                    prefix={
                      <img
                        className="w-3 h-3 object-contain "
                        src="https://cryptologos.cc/logos/solana-sol-logo.svg?v=022"
                        alt="avatar"
                      />
                    }
                  /> */}
                </div>
              </div>

              <div className="md:hidden my-2 w-full text-center">
                <img
                  className="w-48 h-48 m-auto object-contain bg-white rounded-full
                   p-1"
                  src={profileImage?.url}
                  alt="profile"
                />
                <div className="my-5 text-lg">{projectName}</div>
                <div className="flex flex-row align-middle items-center gap-5 w-full content-center justify-center pb-5 mt-2">
                  {SocialLinks.filter(
                    ({ switchKey, linkKey }) =>
                      profileData[switchKey] && profileData[linkKey]?.length > 0
                  ).map(({ switchKey, linkKey, icon }) => (
                    <div
                      key={switchKey}
                      onClick={() =>
                        window.open(profileData[linkKey], "_blank")?.focus()
                      }
                    >
                      {icon}
                    </div>
                  ))}
                </div>

                <div className="flex flex-wrap justify-center">
                  <BookBurnerStat
                    margin="auto"
                    profile={profile}
                  ></BookBurnerStat>
                </div>
                {/* <Statistic
                  title="Book Burner"
                  value={bookBurnerSol as number}
                  loading={!bookBurnerLoaded as boolean}
                  valueStyle={{ color: "#3f8600" }}
                  prefix={
                    <img
                      className="w-3 h-3 object-contain "
                      src="https://cryptologos.cc/logos/solana-sol-logo.svg?v=022"
                      alt="avatar"
                    />
                  }
                /> */}
              </div>
            </header>
          </div>
        </main>
        <div className="container mx-auto">
          <Tabs
            className="block"
            defaultActiveKey="1"
            type="line"
            centered
            size={"large"}
            style={{ marginBottom: 32 }}
          >
            <TabPane tab="Raffles" key="1">
              <GridLayout>
                <div className="container mx-auto">
                  <div className="flex flex-wrap">
                    <GridLayout>
                      {raffles?.map((doc) => (
                        <RaffleItem
                          key={doc.id}
                          raffleDoc={doc}
                          layoutType="grid"
                        />
                      ))}
                      {!raffles &&
                        [1].map(() => {
                          return <RaffleItemSkeleton layoutType="grid" />;
                        })}
                    </GridLayout>
                  </div>
                </div>
              </GridLayout>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </>
  );
};

const className = "w-5 fill-blue-700 hover:fill-blue-800 cursor-pointer";
export const SocialLinks = [
  {
    switchKey: "showTwitter",
    linkKey: "linkTwitter",
    icon: (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        className={className}
        viewBox="0 0 310 310"
      >
        <g id="XMLID_826_">
          <path
            id="XMLID_827_"
            d="M302.973,57.388c-4.87,2.16-9.877,3.983-14.993,5.463c6.057-6.85,10.675-14.91,13.494-23.73
       c0.632-1.977-0.023-4.141-1.648-5.434c-1.623-1.294-3.878-1.449-5.665-0.39c-10.865,6.444-22.587,11.075-34.878,13.783
       c-12.381-12.098-29.197-18.983-46.581-18.983c-36.695,0-66.549,29.853-66.549,66.547c0,2.89,0.183,5.764,0.545,8.598
       C101.163,99.244,58.83,76.863,29.76,41.204c-1.036-1.271-2.632-1.956-4.266-1.825c-1.635,0.128-3.104,1.05-3.93,2.467
       c-5.896,10.117-9.013,21.688-9.013,33.461c0,16.035,5.725,31.249,15.838,43.137c-3.075-1.065-6.059-2.396-8.907-3.977
       c-1.529-0.851-3.395-0.838-4.914,0.033c-1.52,0.871-2.473,2.473-2.513,4.224c-0.007,0.295-0.007,0.59-0.007,0.889
       c0,23.935,12.882,45.484,32.577,57.229c-1.692-0.169-3.383-0.414-5.063-0.735c-1.732-0.331-3.513,0.276-4.681,1.597
       c-1.17,1.32-1.557,3.16-1.018,4.84c7.29,22.76,26.059,39.501,48.749,44.605c-18.819,11.787-40.34,17.961-62.932,17.961
       c-4.714,0-9.455-0.277-14.095-0.826c-2.305-0.274-4.509,1.087-5.294,3.279c-0.785,2.193,0.047,4.638,2.008,5.895
       c29.023,18.609,62.582,28.445,97.047,28.445c67.754,0,110.139-31.95,133.764-58.753c29.46-33.421,46.356-77.658,46.356-121.367
       c0-1.826-0.028-3.67-0.084-5.508c11.623-8.757,21.63-19.355,29.773-31.536c1.237-1.85,1.103-4.295-0.33-5.998
       C307.394,57.037,305.009,56.486,302.973,57.388z"
          />
        </g>
      </svg>
    ),
  },
  {
    switchKey: "showDiscord",
    linkKey: "linkDiscord",
    icon: (
      <svg
        className={className}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M18.942 5.556a16.299 16.299 0 0 0-4.126-1.297c-.178.321-.385.754-.529 1.097a15.175 15.175 0 0 0-4.573 0 11.583 11.583 0 0 0-.535-1.097 16.274 16.274 0 0 0-4.129 1.3c-2.611 3.946-3.319 7.794-2.965 11.587a16.494 16.494 0 0 0 5.061 2.593 12.65 12.65 0 0 0 1.084-1.785 10.689 10.689 0 0 1-1.707-.831c.143-.106.283-.217.418-.331 3.291 1.539 6.866 1.539 10.118 0 .137.114.277.225.418.331-.541.326-1.114.606-1.71.832a12.52 12.52 0 0 0 1.084 1.785 16.46 16.46 0 0 0 5.064-2.595c.415-4.396-.709-8.209-2.973-11.589zM8.678 14.813c-.988 0-1.798-.922-1.798-2.045s.793-2.047 1.798-2.047 1.815.922 1.798 2.047c.001 1.123-.793 2.045-1.798 2.045zm6.644 0c-.988 0-1.798-.922-1.798-2.045s.793-2.047 1.798-2.047 1.815.922 1.798 2.047c0 1.123-.793 2.045-1.798 2.045z" />
      </svg>
    ),
  },
  {
    switchKey: "showWebsite",
    linkKey: "linkWebsite",
    icon: (
      <svg
        className={className}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 195.085 195.085"
      >
        <g>
          <path
            d="M179.617,15.453c-0.051-0.05-0.102-0.1-0.154-0.149c-18.689-18.549-48.477-20.463-69.37-4.441
       c-2.091,1.599-3.776,3.053-5.302,4.575c-0.044,0.044-0.087,0.088-0.13,0.133L71.224,49.012c-2.929,2.929-2.929,7.678,0.001,10.606
       c2.93,2.93,7.679,2.929,10.606-0.001l33.561-33.566c0.035-0.035,0.069-0.07,0.104-0.105c1.023-1.01,2.205-2.02,3.715-3.174
       c15.008-11.508,36.411-10.098,49.789,3.281c0.044,0.044,0.089,0.088,0.134,0.131c14.652,14.786,14.611,38.742-0.124,53.483
       l-33.559,33.563c-2.929,2.929-2.929,7.678,0.001,10.606c1.465,1.464,3.384,2.196,5.303,2.196c1.919,0,3.839-0.732,5.304-2.197
       l33.56-33.563C200.241,69.641,200.241,36.077,179.617,15.453z"
          />
          <path
            d="M113.23,135.437l-33.541,33.542c-0.066,0.067-0.132,0.136-0.196,0.205c-3.708,3.648-8.059,6.449-12.945,8.333
       c-13.995,5.418-29.888,2.07-40.481-8.524c-14.768-14.784-14.768-38.84,0-53.619L59.624,81.83c1.406-1.407,2.197-3.315,2.197-5.305
       v-0.013c0-4.143-3.357-7.494-7.5-7.494c-2.135,0-4.062,0.895-5.428,2.328l-33.435,33.422c-20.61,20.628-20.612,54.195-0.002,74.828
       c10.095,10.097,23.628,15.479,37.411,15.479c6.414-0.001,12.884-1.167,19.084-3.566c6.922-2.667,13.088-6.67,18.326-11.896
       c0.076-0.075,0.15-0.153,0.223-0.232l33.337-33.337c2.929-2.93,2.929-7.678-0.001-10.607
       C120.909,132.509,116.16,132.509,113.23,135.437z"
          />
          <path
            d="M59.15,135.908c1.465,1.465,3.384,2.197,5.304,2.197c1.919,0,3.839-0.732,5.303-2.196l66.164-66.161
       c2.93-2.929,2.93-7.678,0.001-10.606c-2.929-2.93-7.678-2.929-10.606-0.001l-66.164,66.161
       C56.221,128.23,56.221,132.979,59.15,135.908z"
          />
        </g>
      </svg>
    ),
  },
  {
    switchKey: "showSecondaryMarket",
    linkKey: "linkSecondaryMarket",
    icon: (
      <svg
        className={className}
        width="32.001px"
        height="32.001px"
        viewBox="0 0 32.001 32.001"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Lager_64" data-name="Lager 64" transform="translate(0)">
          <g id="Group_30" data-name="Group 30">
            <path
              id="Path_79"
              data-name="Path 79"
              d="M30.129,5,27,1.871A6.328,6.328,0,0,0,22.5.017c-.166,0-.332.006-.5.019V0H10V.036C9.834.023,9.668.017,9.5.017A6.328,6.328,0,0,0,5,1.871L1.871,5a6.387,6.387,0,0,0,0,9.034l.091.091a6.388,6.388,0,0,0,9.034,0l.331-.33A5.926,5.926,0,0,0,15.94,16h.12a5.926,5.926,0,0,0,4.613-2.2l.331.33a6.388,6.388,0,0,0,9.034,0l.091-.091A6.387,6.387,0,0,0,30.129,5Zm-2.828,6.2-.092.092a2.389,2.389,0,0,1-3.377,0l-.33-.331L20.674,8.143a.4.4,0,0,0-.587.03l-2.52,3.1A1.933,1.933,0,0,1,16.06,12h-.12a1.933,1.933,0,0,1-1.507-.722L11.961,8.231a.464.464,0,0,0-.688-.035L8.5,10.97l-.33.331a2.389,2.389,0,0,1-3.377,0L4.7,11.209a2.389,2.389,0,0,1,0-3.377L7.832,4.7A2.355,2.355,0,0,1,9.5,4.017c.064,0,.434-.022.5-.017H22c.064-.005.434.017.5.017a2.355,2.355,0,0,1,1.67.682L27.3,7.832A2.389,2.389,0,0,1,27.3,11.209Z"
            />
            <path
              id="Path_80"
              data-name="Path 80"
              d="M26.02,20h-.04A1.979,1.979,0,0,0,24,21.98V27a1,1,0,0,1-1,1H9a1,1,0,0,1-1-1V21.98A1.979,1.979,0,0,0,6.02,20H5.98A1.979,1.979,0,0,0,4,21.98v8.04A1.979,1.979,0,0,0,5.98,32H26.02A1.979,1.979,0,0,0,28,30.02V21.98A1.979,1.979,0,0,0,26.02,20Z"
            />
          </g>
        </g>
      </svg>
    ),
  },
];

export const ProjectProfilePageSkeleton = () => {
  return (
    <div
      className="text-gray-600 body-font overflow-hidden relative "
      style={{ height: "calc(100vh - 70px)", overflow: "auto" }}
    >
      <main className=" bg-opacity-25">
        <div className=" lg:w-8/12 lg:mx-auto mb-8">
          <header className="flex flex-wrap items-center p-4 md:py-8">
            <div className="md:w-3/12 md:ml-16  hidden md:block">
              <Skeleton.Avatar
                className="w-20 h-20 md:w-40 md:h-40 object-contain bg-white rounded-full "
                style={{ width: "10rem", height: "10rem" }}
                active
                size={"large"}
              />
            </div>

            <div className="w-8/12 md:w-7/12 ml-4  hidden md:block">
              <div className="md:flex md:flex-wrap md:items-center mb-4">
                <h2 className="text-3xl inline-block font-light md:mr-2 mb-2 sm:mb-0">
                  <Space>
                    <Skeleton.Input size="large" active />
                  </Space>
                </h2>
              </div>
            </div>

            <div className="md:hidden my-2 w-full text-center">
              <Skeleton.Avatar
                className="w-48 h-48 m-auto object-contain bg-white rounded-full"
                style={{ width: "12rem", height: "12rem" }}
                active
                size={"large"}
              />
              <div className="my-5 text-lg">
                <Space>
                  <Skeleton.Input size="large" active />
                </Space>
              </div>
            </div>
          </header>
        </div>
      </main>
      <div className="container mx-auto">
        <Tabs
          className="block"
          defaultActiveKey="1"
          type="line"
          centered
          size={"large"}
          style={{ marginBottom: 32 }}
        >
          <TabPane tab="Raffles" key="1">
            <GridLayout>
              <div className="container mx-auto">
                <div className="flex flex-wrap">
                  <GridLayout>
                    {[1].map(() => {
                      return <RaffleItemSkeleton layoutType="grid" />;
                    })}
                  </GridLayout>
                </div>
              </div>
            </GridLayout>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};
