import "./RafflesEndingSoon.scss";

import firebase from "firebase";
import { useEffect, useState } from "react";
import { useFirebase } from "../../../../Auth/FirebaseService";
import { CarouselSection } from "../../../../Compontents/CarouselSection/CarouselSection";
import { CarouselLayout } from "../../../../Layout/CarouselLayout/CarouselLayout";
import {
  RaffleItem,
  RaffleItemSkeleton,
} from "../../../../Compontents/RaffleItem/RaffleItem";
import { Button } from "react-daisyui";
import { useNavigate } from "react-router-dom";

export const RafflesEndingSoon = () => {
  const { firestore } = useFirebase();
  const navigate = useNavigate();
  const [raffles, setRaffles] =
    useState<
      firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]
    >();

  useEffect(() => {
    getEndingSoonRaffles();
  }, []);

  const getEndingSoonRaffles = async () => {
    firestore
      .collection(`ARaffle`)
      .where("status", "in", ["running", "drawing"])
      .where("creatorType", "==", "project")
      .orderBy("endDate", "asc")
      .limit(12)
      .get()
      .then(({ docs }) => {
        setRaffles(docs);
      })
      .catch((err) => {
        console.error("getRecentlyEndingSoonRaffles", err);
      });
  };
  return (
    <div className=" flex flex-col justify-center items-center mt-20">
      <div className=" font-bold text-4xl">Ending soon</div>
      <div className="container mx-auto mt-10">
        {raffles && (
          <CarouselLayout>
            {raffles.map((raffleDoc) => (
              <RaffleItem
                key={raffleDoc.id}
                autoLoad={true}
                raffleDoc={raffleDoc}
                layoutType="carousel"
              />
            ))}
            {!raffles &&
              [1].map(() => {
                return <RaffleItemSkeleton layoutType="carousel" />;
              })}
          </CarouselLayout>
        )}
      </div>
      <Button
        onClick={() => navigate("/raffles")}
        color="primary"
        className="text-white  mt-3"
        style={{ color: "white" }}
      >
        SEE ALL RAFFLES
      </Button>
    </div>
  );
};
