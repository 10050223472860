import { Alert, Drawer, Spin } from "antd";
import { useState } from "react";
import { Navbar, Button, Dropdown } from "react-daisyui";
import Marquee from "react-fast-marquee";
import { Link, NavLink } from "react-router-dom";
import { useFirebase } from "../../Auth/FirebaseService";
import { useSignin } from "../../Auth/SigninService/SigninService";
import { useTPS } from "../../Hooks/useTPS";
import { useSolanaHealth } from "../../Services/SolanaHealthService/SolanaHealthService";
import Logo from "../../assets/DV_favicon.jpeg";
import "./NavBar.scss";

const WordSpace = (
  <>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </>
);
const routes = [
  {
    text: "VAULTS",
    route: "/vaults",
  },
  {
    text: "RAFFLES",
    route: "/raffles",
  },
  {
    text: "PROJECTS",
    route: "/projects",
  },
  // {
  //   text: "REWARDS",
  //   route: "/engage",
  // },
  // {
  //   text: "BOOK BURNER",
  //   route: "/sd",
  // },
];
export const NavBar = () => {
  const { userDoc, loadingUseDocrState, loadingUserState } = useFirebase();
  const { solanaHealth } = useSolanaHealth();
  const [showNav, setShowNav] = useState(false);
  const { setShowSigninModal, getNavbarAuthCTA } = useSignin();
  const TPS = useTPS();

  return (
    <>
      {solanaHealth?.showAlertBanner && (
        <Alert
          style={{ zIndex: 1001 }}
          // banner

          type={solanaHealth?.bannarBGColor as any}
          message={
            <Marquee gradientColor={[235, 233, 225]} gradient={false}>
              <div
                style={{
                  color: "#000",
                }}
              >
                {new Array(50).fill(0).map(() => (
                  <>
                    {WordSpace}
                    {solanaHealth?.bannerMessage}
                  </>
                ))}
              </div>
            </Marquee>
          }
        />
      )}
      <Drawer
        title={
          <div className="dv-logo">
            <span className="diamond">DIAMOND</span>
            <span className="vualts">VAULTS</span>
          </div>
        }
        placement={"left"}
        className="mobile-drawer"
        width="270px"
        closable={true}
        onClose={() => setShowNav(false)}
        visible={showNav}
        key={"left"}
      >
        <div className="flex flex-col ">
          <NavLink
            onClick={() => {
              setShowNav(false);
              window.scrollTo(0, 0);
            }}
            to={"/"}
            className={({ isActive }) =>
              !isActive ? "nav-item" : "nav-item-selected"
            }
          >
            HOME
          </NavLink>
          {routes.map((route) => (
            <NavLink
              key={JSON.stringify(route)}
              onClick={() => {
                setShowNav(false);
                window.scrollTo(0, 0);
              }}
              to={route.route}
              className={({ isActive }) =>
                !isActive ? "nav-item" : "nav-item-selected"
              }
            >
              {route.text}
            </NavLink>
          ))}
        </div>
      </Drawer>
      <div className="flex w-full component-preview p-4 items-center justify-center gap-2 font-sans nav  relative shadow-sm">
        <Navbar>
          <div className="navbar-start">
            <label
              className="btn btn-ghost lg:hidden"
              onClick={() => setShowNav(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h8m-8 6h16"
                />
              </svg>
            </label>

            <Link to={"/"}>
              <div className="dv-logo">
                <img
                  className=" block sm:hidden"
                  style={{ width: "30px" }}
                  src={Logo}
                  alt=""
                />
                <span className="hidden sm:contents diamond">DIAMOND</span>
                <span className="hidden sm:contents vualts">VAULTS</span>
              </div>
              <div className="hidden sm:flex   flex-row">
                <div style={{ fontSize: "10px", fontWeight: 500 }}>TPS: </div>
                &nbsp;
                <div style={{ fontSize: "10px", fontWeight: 800 }}>
                  {TPS?.toLocaleString()}
                </div>
              </div>
            </Link>
          </div>
          <div className="navbar-center hidden lg:flex">
            <ul className="list">
              {routes.map((route) => (
                <NavLink
                key={JSON.stringify(route)}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={route.route}
                  className={({ isActive }) =>
                    !isActive ? "items" : "items-selected"
                  }
                >
                  {route.text}
                </NavLink>
              ))}
            </ul>
          </div>
          <div className="navbar-end">{getNavbarAuthCTA()}</div>
        </Navbar>
      </div>
    </>
  );
};
