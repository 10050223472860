import "./RecentlyJoined.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useFirebase } from "../../../../Auth/FirebaseService";
import firebase from "firebase";
import { useEffect, useState } from "react";
import {
  ProjectItem,
  ProjectItemSkeleton,
} from "../../../../Compontents/ProjectItem/ProjectItem";
import { Button } from "react-daisyui";
import { useNavigate } from "react-router-dom";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
    slidesToSlide: 1,
    paritialVisibilityGutter: 60,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1400 },
    items: 4,
    slidesToSlide: 1,
    paritialVisibilityGutter: 60,
  },
  tablet: {
    breakpoint: { max: 1400, min: 1000 },
    paritialVisibilityGutter: 60,
    items: 2,
    slidesToSlide: 1,
  },
  tablet2: {
    breakpoint: { max: 1000, min: 464 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const RecentlyJoined = () => {
  const { firestore } = useFirebase();
  const navigate = useNavigate();
  const [projects, setProjects] =
    useState<
      firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]
    >();

  useEffect(() => {
    getRecentlyJoinedProjects();
  }, []);

  const getRecentlyJoinedProjects = async () => {
    firestore
      .collectionGroup(`Profile`)
      .where("verified", "==", true)
      .orderBy("verifiedDate", "desc")
      .limit(12)
      .get()
      .then(({ docs }) => {
        setProjects([...docs.filter((doc) => doc.data().projectName)]);
      })
      .catch((err) => {
        console.error("getRecentlyEndingSoonRaffles", err);
      });
  };
  return (
    <div className=" flex flex-col justify-center items-center mt-40">
      <div className=" font-bold text-4xl">Recently Joined</div>
      <div className="container mx-auto mt-10 ">
        {projects && (
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={true}
            responsive={responsive}
            infinite={true}
            autoPlay
            // autoPlay={this.props.deviceType !== "mobile" ? true : false}
            autoPlaySpeed={4000}
            className="pb-10 w-full"
            keyBoardControl={true}
            // customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["mobile"]}
            // deviceType={""}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            {projects?.map((projectDoc) => (
              <ProjectItem
                key={projectDoc.id}
                projectDoc={projectDoc}
                type="carousel"
              />
            ))}
            {!projects &&
              [20].map(() => {
                return <ProjectItemSkeleton />;
              })}
          </Carousel>
        )}

        {!projects && (
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={true}
            responsive={responsive}
            infinite={true}
            // autoPlay={this.props.deviceType !== "mobile" ? true : false}
            autoPlaySpeed={1000}
            className="pb-10 w-full"
            keyBoardControl={true}
            // customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["mobile"]}
            // deviceType={""}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            {!projects &&
              [20].map(() => {
                return <ProjectItemSkeleton />;
              })}
          </Carousel>
        )}
      </div>
      <Button
        onClick={() => navigate("/projects")}
        color="primary"
        className="text-white  mt-3"
        style={{ color: "white" }}
      >
        SEE ALL PROJECTS
      </Button>
    </div>
  );
};
