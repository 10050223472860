type GridLayoutProps = {
  children: any;
};

export const GridLayout: React.FC<GridLayoutProps> = ({ children }) => {
  return (
    <div className="container mx-auto min-h-full">
      <div className="flex flex-wrap items-start">{children}</div>
    </div>
  );
};
