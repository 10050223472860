import "./BookBurner.scss";

export const BookBurner = () => {
  return (
    <div className="bookburner-container">
      <div className="dv-logo flex flex-col items-center">
        <div>
          <span className="diamond">Book</span>
          <span className="vualts">Burner</span>
        </div>
      </div>
      <section className=" body-font mb-20">
        <div className="container px-5  mx-auto">
          <div className="text-center ">
            <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
              <div style={{ fontSize: "24px", fontWeight: 200 }}>
                The Book Burner will be used to provide liquidity for tokens
                you've earned from staking.
              </div>
            </p>
          </div>
          <div
            style={{
              margin: "auto",
              textAlign: "center",
              fontSize: "33px",
              fontWeight: 900,
              opacity: 0.4,
            }}
          >
            💚 COMING SOON 💚
          </div>
        </div>
      </section>
    </div>
  );
};
