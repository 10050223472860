import { Connection } from "@solana/web3.js";
import { useEffect, useState } from "react";
import { privateRPC } from "../Web3/utils";

export const useTPS = () => {
  const [TPS, setTPS] = useState<number | undefined>();

  useEffect(() => {
    getTPS();
    setInterval(() => {
      getTPS();
    }, 30000);
  }, []);

  const getTPS = async () => {
    try {
      const connection = new Connection(privateRPC, "confirmed");
      const res = await connection.getRecentPerformanceSamples(1);
      if (res[0]) {
        const { numTransactions, samplePeriodSecs } = res[0];
        if (numTransactions && samplePeriodSecs) {
          const sample = +(numTransactions / samplePeriodSecs).toFixed(0);
          return setTPS(sample);
        }
      }
    } catch (err) {}
    setTPS(undefined);
  };

  return TPS;
};
