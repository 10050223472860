import firebase from "firebase";
import { useRaffleStatus } from "../../../Hooks/useRaffleStatus";
import Countdown from "react-countdown";
import { Alert } from "antd";
import React from "react";
import Marquee from "react-fast-marquee";
type RaffleOverlayStatusProps = {
  raffleDoc:
    | firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
    | undefined;
};

const WordSpace = (
  <>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </>
);

export const RaffleOverlayStatus: React.FC<RaffleOverlayStatusProps> = ({
  raffleDoc,
}) => {
  const raffleStatus = useRaffleStatus(raffleDoc);

  const raffleData = raffleDoc?.data();
  if (!raffleDoc || !raffleData || raffleStatus === "LOADING") return <></>;

  const { endDate } = raffleData;

  const isLive = () => raffleStatus === "LIVE" || raffleStatus === "ENDING";

  const getMarquee = () => {
    switch (raffleStatus) {
      case "LIVE":
        return {
          msg: `LIVE ${raffleData.type === "NFT" ? "NFT" : "WHITELIST"} RAFFLE`,
          color: "#62a770",
        };
      case "ENDING":
        return {
          msg: "ENDING SOON!",
          color: "#ff9800",
        };
      case "DRAWING":
        return {
          msg: "BUSY DRAWING",
          color: "",
        };
      case "ENDED":
        return {
          msg: "ENDED",
          color: "",
        };
    }
  };

  return (
    <section className="text-gray-600 body-font overflow-hidden mb-10">
      {/* <Alert
        // banner
        
        type="success"
        message={ */}
      <Marquee gradientColor={[235, 233, 225]} gradient={false}>
        <div
          style={{
            color: getMarquee().color,
          }}
        >
          {new Array(10).fill(0).map(() => (
            <>
              <b>{getMarquee().msg}</b>
              <span className=" hidden lg:inline">
                {WordSpace}
                {WordSpace}
              </span>
              {WordSpace}
            </>
          ))}
        </div>
      </Marquee>
      {/* }
      /> */}

      {/* {isLive() && (
        <div className="pt-2 grid text-center items-center my-6 raffle-overlay-countdown">
          <div>TIME LEFT</div>
          <Countdown date={endDate.toDate()} renderer={renderer} />
        </div>
      )} */}
    </section>
  );
};

const Completionist = () => (
  <span style={{ fontSize: "13px" }}>RAFFLE ENDED</span>
);

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
  if (completed) {
    // Render a complete state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <div className="time-left">
        <div className="items-container">
          <div className="item-container">
            <div className="item-value">{days}</div>
            <div className="item-title">D</div>
          </div>
          <div className="countdown-divider"></div>
          <div className="item-container">
            <div className="item-value">{hours}</div>
            <div className="item-title">H</div>
          </div>
          <div className="countdown-divider"></div>
          <div className="item-container">
            <div className="item-value">{minutes}</div>
            <div className="item-title">M</div>
          </div>
          <div className="countdown-divider"></div>
          <div className="item-container">
            <div className="item-value">{seconds}</div>
            <div className="item-title">S</div>
          </div>
        </div>
      </div>
    );
  }
};
