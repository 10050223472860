import firebase from "firebase";
import moment from "moment";
import { useEffect, useState } from "react";

type RaffleStatus = "LOADING" | "LIVE" | "ENDING" | "DRAWING" | "ENDED";

export const useRaffleStatus = (
  raffleDoc:
    | firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
    | firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
    | undefined
) => {
  const { endDate, status } = raffleDoc?.data() || {};
  const [hoursLeft, setHoursLeft] = useState<any>();
  const [timedout, setTimedout] = useState(false);
  const [raffleStatus, setRaffleStatus] = useState<RaffleStatus>("LOADING");

  const monitor = () => {
    if (raffleDoc) {
      const duration = moment.duration(
        moment(endDate.toDate()).diff(new Date())
      );
      const hours = duration.asHours();
      if (hours <= 0) {
        setTimedout(true);
        setHoursLeft(0);
      } else {
        setHoursLeft(hours);
        const interval = setInterval(() => {
          const duration = moment.duration(
            moment(endDate.toDate()).diff(new Date())
          );
          const hours = duration.asHours();
          if (hours <= 0) {
            setTimedout(true);
            setHoursLeft(0);
            clearInterval(interval);
          } else {
            setHoursLeft(hours);
          }
        }, 1000);
        return () => clearInterval(interval);
      }
    }
  };

  useEffect(() => {
    return monitor();
  }, [raffleDoc]);

  useEffect(() => {
    if (hoursLeft !== undefined) {
      if (hoursLeft > 2) {
        setRaffleStatus("LIVE");
      } else {
        if (timedout) {
          setRaffleStatus(status === "ended" ? "ENDED" : "DRAWING");
        } else {
          setRaffleStatus("ENDING");
        }
      }
    }
  }, [hoursLeft, timedout, status]);

  return raffleStatus;
};
