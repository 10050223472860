import { Footer } from "../../Layout/Footer/Footer";
import "./LandingPage.scss";
import { Hero } from "./Old/Sections/Hero/Hero";
import { BookBurner } from "./Sections/BookBurner/BookBurner";
import { RafflesEndingSoon } from "./Sections/RafflesEndingSoon/RafflesEndingSoon";
import { RecentlyJoined } from "./Sections/RecentlyJoined/RecentlyJoined";
import { TopVaults } from "./Sections/TopVaults/TopVaults";

export const LandingPage = () => {
  return (
    <div style={{ overflow: "auto" }}>
      <div className=" w-full lg:w-5s/6  m-auto">
        <Hero/>
        <RafflesEndingSoon/>
        <TopVaults />
        <RecentlyJoined />
        <BookBurner/>
      </div>
      <Footer/>

    </div>
  );
};
