import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Descriptions,
  notification,
  PageHeader,
  Skeleton,
  Statistic,
  Tag,
} from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Range, Pagination, Stats, Form, Button } from "react-daisyui";
import { useFirebase } from "../../Auth/FirebaseService";
import firebase from "firebase";
import { Tabs, Drawer, Checkbox, Segmented, Radio, Space } from "antd";
import { Col, InputNumber, Row, Slider } from "antd";
import "./RaffleOverlay.scss";
import {
  useWalletModal,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import { useWallet } from "@solana/wallet-adapter-react";
import { PurchaseTicketsForm } from "./PurchaseTicketsForm/PurchaseTicketsForm";
import Countdown from "react-countdown";
import { Empty } from "antd";
import { useRaffleStatus } from "../../Hooks/useRaffleStatus";
import { RaffleOverlayStatus } from "./RaffleOverlayStatus/RaffleOverlayStatus";
import { RaffleParticipants } from "./RaffleParticipants/RaffleParticipants";
import SkeletonAvatar from "antd/lib/skeleton/Avatar";
import { DotChartOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;

export const RaffleOverlaySkeleton = () => {
  const [_, next] = useSearchParams();
  return (
    <>
      <PageHeader
        className="site-page-header-responsive"
        onBack={() => next({})}
        // onBack={() => navigate("/")}

        title={
          <div className="flex items-center py-0 pt-3 align-middle justify-items-center">
            <SkeletonAvatar className="rounded-full object-contain mr-4 shadow"></SkeletonAvatar>
            <Skeleton.Input active></Skeleton.Input>
          </div>
        }
      ></PageHeader>

      <section className="text-gray-600 body-font overflow-hidden mt-16">
        <div className="container px-5 pb-24 mx-auto">
          <div className="lg:w-4/5 mx-auto flex flex-wrap align-top items-start justify-center">
            <div className="lg:w-1/3 w-full">
              <div className="w-full h-auto flex justify-center">
                <Skeleton.Node
                  active
                  style={{ width: "200px", height: "200px", margin: "auto" }}
                  className="w-full h-auto object-contain object-center rounded"
                >
                  <DotChartOutlined
                    style={{ fontSize: 40, color: "#bfbfbf" }}
                  />
                </Skeleton.Node>
              </div>
            </div>
            <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
              <h2 className="text-sm title-font text-gray-500 tracking-widest">
                <Skeleton></Skeleton>
              </h2>
              <h1 className="text-gray-900 text-3xl title-font font-medium mb-4">
                <Skeleton></Skeleton>
              </h1>

              <h1 className="text-gray-900 text-3xl title-font font-medium mb-4">
                <Skeleton></Skeleton>
              </h1>
            </div>
            {/* 
          
              <Tabs
                defaultActiveKey="1"
                activeKey={activeKey}
                onChange={(e) => setActiveKey(e)}
              >
                {isLive() && (
                  <TabPane tab="Enter Raffle" key="1">
                    <PurchaseTicketsForm
                      raffleDoc={raffleDoc}
                      onConnectWallet={handlePurchase}
                    />
                  </TabPane>
                )}
                {isLive() === false && (
                  <TabPane tab="Results" key="1">
                    Content of Tab Pane 2
                  </TabPane>
                )}
                <TabPane tab="Participants" key="2">
                  <RaffleParticipants
                    activeKey={activeKey}
                    enterRaffle={enterRaffle}
                    raffleDoc={raffleDoc}
                  />
                </TabPane>
              </Tabs>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};
