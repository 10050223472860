import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const VaultRedirect = () => {
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (params["id"]) {
      window.location.href = `https://stake.diamondvaults.io/vault/${params["id"]}`;
    } else {
      navigate("/");
    }
  }, []);

  return <></>;
};
