/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react";
import { getUserRoleInServer } from "../API/Raffles/BuyRaffleTicket";
import { useFirebase } from "../Auth/FirebaseService";

const CACHE_MINUTES = 5;

export const useCommunityRoles = (raffleIdDoc: any) => {
  const { user } = useFirebase();
  const [loading, setLoading] = useState(false);
  const [canPurchase, setCanPurchase] = useState(false);
  const [matchingRoles, setMatchingRole] = useState<any>();
  useEffect(() => {
    if (raffleIdDoc && user && loading === false && !matchingRoles) {
      const { buyers } = raffleIdDoc.data();
      if (buyers === "holders") {
        canGetTickets().then();
      } else {
        setCanPurchase(true);
        setMatchingRole([]);
      }
    }
  }, [raffleIdDoc, user, loading, matchingRoles]);

  const canGetTickets = async () => {
    const { vaultId } = raffleIdDoc.data();
    if (localStorage.getItem(vaultId)) {
      const { lastFetch, userId } = JSON.parse(localStorage.getItem(vaultId)!);
      const lastest = moment().subtract(CACHE_MINUTES, "minutes");
      const invalid = lastest.isAfter(moment(new Date(lastFetch)));
      if (invalid || userId !== user!.uid) {
        await getLatestRoles();
      }
    } else {
      await getLatestRoles();
    }
    confirmMembership();
  };

  const getLatestRoles = async () => {
    if (user) {
      const { raffleId, vaultId } = raffleIdDoc.data();
      const {
        data: { serverId, roles },
      } = await getUserRoleInServer(await user.getIdToken(), raffleId);
      const payload = {
        serverId,
        userRoles: roles,
        userId: user!.uid,
        lastFetch: Date.now(),
      };
      localStorage.setItem(vaultId, JSON.stringify(payload));
    }
  };
  const confirmMembership = () => {
    const { vaultId, roles } = raffleIdDoc.data();
    const stringData = localStorage.getItem(vaultId);
    if (stringData && roles) {
      const { userRoles } = JSON.parse(stringData);
      console.log(userRoles);

      const matchingRoles = roles.filter(({ id }: any) =>
        userRoles.some((userRole: any) => userRole.id === id)
      );
      setCanPurchase(matchingRoles.length > 0);
      setMatchingRole(matchingRoles);
      setLoading(false);
      return;
    }
    setCanPurchase(false);
    setMatchingRole([]);
    setLoading(false);
  };

  return {
    loadingRoles: loading,
    matchingRoles,
    canPurchase,
  };
};
