import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/storage";
import { createContext, useContext, useEffect, useState } from "react";
import * as axios from "axios";

let app: firebase.app.App | undefined = firebase.initializeApp({
  apiKey: "AIzaSyABZVLQeN4r91nlyRHYww3Nr5yxblLRrfY",
  authDomain: "diamondvaults.io",
  projectId: "nft-anybodies",
  storageBucket: "nft-anybodies.appspot.com",
  messagingSenderId: "773321643955",
  appId: "1:773321643955:web:4d92166eafd8ab3958ddc6",
  measurementId: "G-QFKFEHZ0WL",
});

export const firestore = app.firestore();
firestore.settings({ experimentalForceLongPolling: true });
export const auth = app.auth();
export const storage = app.storage();
export const functions = app.functions();

type FirebaseFeatures = {
  firestore: firebase.firestore.Firestore;
  auth: firebase.auth.Auth;
  functions: firebase.functions.Functions;
  storage: firebase.storage.Storage;
  loadingUserState: boolean;
  loadingUseDocrState: boolean;
  user: firebase.User | null;
  userDoc:
    | firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
    | undefined;
  customFirebaseRequest: (functionName: string, data: any) => Promise<any>;
  signupWithTwitter?: () => Promise<any>;
  setLoadingUserState?: any;
  setLoadingUserDocState?: any;
};

const customFirebaseRequest = (functionName: string, data: any = {}) =>
  new Promise<any>((resolve) => {
    // axios.default
    //   .post<any>(
    //     `http://localhost:5000/nft-anybodies/us-central1/${functionName}`,
    //     {
    axios.default
      .post<any>(
        `https://us-central1-nft-anybodies.cloudfunctions.net/${functionName}`,
        {
          data,
        }
      )
      .then((response) => {
        // handle success
        // console.log("response", response.data);
        resolve(response.data);
      })
      .catch((error: any) => {
        // handle error
        console.log(error);
        return resolve(undefined);
      });
  });

const FirebaseContext = createContext<FirebaseFeatures>({
  firestore,
  auth,
  functions,
  storage,
  loadingUserState: true,
  user: null,
  customFirebaseRequest,
  userDoc: undefined,
  loadingUseDocrState: false,
});

export function useFirebase() {
  return useContext(FirebaseContext);
}

export const FirebaseService = ({ children }: any) => {
  const [loadingUserState, setLoadingUserState] = useState(true);
  const [loadingUseDocrState, setLoadingUserDocState] = useState(true);
  const [user, setUser] = useState<firebase.User | null>(null);
  const [userDoc, setDoc] =
    useState<
      firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
    >();
  useEffect(() => {
    return auth.onAuthStateChanged((user) => {
      console.log("User lggg");
      setUser(user);
      setLoadingUserState(false);
    });
  }, []);

  useEffect(() => {
    // if (!loadingUserState) {
    console.log(user);

    if (user) {
      setLoadingUserDocState(true);
      return firestore.doc(`ATwitterUser/${user.uid}`).onSnapshot((doc) => {
        console.log(doc.data());
        if (doc.exists) {
          setDoc(doc);
        }
        setLoadingUserDocState(false);
      });
    } else {
      setLoadingUserDocState(false);
    }
    // }
  }, [user]);

  const value: FirebaseFeatures = {
    firestore,
    auth,
    functions,
    storage,
    loadingUserState,
    user,
    userDoc,
    loadingUseDocrState,
    customFirebaseRequest,
    setLoadingUserState,
    setLoadingUserDocState,
  };
  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  );
};
