import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Descriptions, notification, PageHeader, Spin, Tag } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "react-daisyui";
import { useFirebase } from "../../Auth/FirebaseService";
import firebase from "firebase";
import { Tabs, Drawer } from "antd";
import "./RaffleOverlay.scss";
import {
  useWalletModal,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import { useWallet } from "@solana/wallet-adapter-react";
import { PurchaseTicketsForm } from "./PurchaseTicketsForm/PurchaseTicketsForm";
import Countdown from "react-countdown";
import { useRaffleStatus } from "../../Hooks/useRaffleStatus";
import { RaffleOverlayStatus } from "./RaffleOverlayStatus/RaffleOverlayStatus";
import { RaffleParticipants } from "./RaffleParticipants/RaffleParticipants";
import { RaffleOverlaySkeleton } from "./RaffleOverlaySkeleton";
import { RaffleResults } from "./RaffleResults/RaffleResults";
import moment from "moment";
import { useSolanaHealth } from "../../Services/SolanaHealthService/SolanaHealthService";
import { raffleExample } from "../../Pages/ExploreRafflesPage/ExploreRafflesPage";
import Linkify from "react-linkify";
import { TwitterTimelineEmbed } from "react-twitter-embed";

const { TabPane } = Tabs;

export const RaffleOverlay = () => {
  const [params, next] = useSearchParams();
  const [raffleId, setRaffleId] = useState<any>();
  const { publicKey } = useWallet();

  useEffect(() => {
    if (publicKey) {
      notification["success"]({
        message: "Wallet is connected",
        placement: "top",
      });
    }
  }, [publicKey]);

  useEffect(() => {
    console.log(params.get("raffle"));
    if (params.get("raffle")) {
      setRaffleId(params.get("raffle"));
    } else {
      setRaffleId(undefined);
    }
  }, [params]);
  useEffect(() => {}, [raffleId]);
  //   if (!raffleId) return <></>;
  return (
    <Drawer
      // title="Filter Raffles"
      placement="bottom"
      width={"100vw"}
      height={"100%"}
      className="raffle-details-overlay"
      // onClose={() => {
      //   window.history.back();
      // }}
      visible={!!raffleId}
    >
      {raffleId ? (
        <RaffleDetailsPageContent raffleId={raffleId} />
      ) : (
        <RaffleOverlaySkeleton />
      )}
    </Drawer>
  );
};

export const RaffleDetailsPageContent = ({ raffleId }: any) => {
  const { firestore } = useFirebase();
  const { solanaHealth } = useSolanaHealth();
  const [_, next] = useSearchParams();
  const ref = useRef<any>();
  const { publicKey } = useWallet();
  const [raffleDoc, setRaffleDoc] = useState<
    firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> | any
  >();
  const [raffleRef, setRaffleRef] =
    useState<
      firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
    >();
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState("1");
  const [loadingTwitter, setLoadingTwitter] = useState(true);

  const enterRaffle = () => setActiveKey("1");
  const raffleStatus = useRaffleStatus(raffleDoc);
  const isLive = () => raffleStatus === "LIVE" || raffleStatus === "ENDING";

  const handlePurchase = () => {
    if (!publicKey) {
      console.log(ref.current.firstChild);
      ref.current.firstChild.click();
      // walletModal.setVisible(true);
    } else {
      console.log(publicKey.toString());
    }
  };

  useEffect(() => {
    getRaffle();
  }, []);

  const getRaffle = () => {
    if (raffleId) {
      return firestore.doc(`/ARaffle/${raffleId}`).onSnapshot((snap) => {
        if (snap.exists === false) return navigate("/");
        setRaffleRef(snap.ref);
        if (!raffleDoc && snap.data()!.type === "WhiteList") {
          setActiveKey("3");
        }
        setRaffleDoc(snap);

        // setRaffleDoc({
        //   ref: snap.ref,
        //   id: "GUv7QbENYO1KbyosqPUS",
        //   data: () => {
        //     return raffleExample;
        //   },
        // } as any);
      });
    } else {
      navigate("/");
    }
  };

  const raffleData = raffleDoc?.data();
  if (!raffleDoc || !raffleData) return <RaffleOverlaySkeleton />;

  const {
    numOfTickets,
    endDate,
    type,
    availableSpots,
    vaultId,
    profile,
    purchasedTickets,
    metadata,
  } = raffleData;

  const getRaffleStatusText = () => {
    switch (raffleStatus) {
      case "DRAWING":
        return "Drawing winners";
      case "ENDED":
        return "Ended";
      case "ENDING":
        return "Ending soon";
      case "LIVE":
        return "Live";
      case "LOADING":
        return "...";

      default:
        break;
    }
  };

  return (
    <>
      <PageHeader
        className="site-page-header-responsive"
        onBack={() => next({})}
        // onBack={() => navigate("/")}
        extra={[
          <div ref={ref}>
            <WalletMultiButton />
          </div>,
        ]}
        title={
          <Link to={`/projects/${vaultId}`}>
            <div className="flex items-center py-0 pt-3 align-middle justify-items-center">
              <img
                className="w-10 h-10 rounded-full object-contain mr-4 shadow bg-white"
                src={profile.image}
                alt="avatar"
              />
              <div className="font-semibold text-sm text-black -mt-1 p-0">
                {profile.name}
              </div>
            </div>
          </Link>
        }
      ></PageHeader>

      <section className="text-gray-600 body-font overflow-hidden">
        <RaffleOverlayStatus raffleDoc={raffleDoc} />
        <div className="container px-5 pb-24 mx-auto">
          <div className="lg:w-4/5 mx-auto flex flex-wrap align-top items-start justify-center">
            <div className="lg:w-1/3 w-full">
              <img
                alt="ecommerce"
                className="w-full h-auto object-contain object-center rounded shadow-lg"
                src={metadata.image}
              />
              {type === "NFT" && (
                <NFTAttributes attributes={metadata?.attributes} />
              )}
              {type === "WhiteList" && metadata?.twitter && (
                <div className=" hidden lg:block">
                  <div className="mt-10">
                    {loadingTwitter && <Spin></Spin>}
                    <TwitterTimelineEmbed
                      options={{
                        height: "600px",
                      }}
                      onLoad={() => {
                        setLoadingTwitter(false);
                      }}
                      screenName={
                        metadata?.twitter.split("/")[
                          metadata?.twitter.split("/").length - 1
                        ]
                      }
                      sourceType="profile"
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
              {type === "NFT" && (
                <>
                  <h2 className="text-sm title-font text-gray-500 tracking-widest">
                    {metadata.collection?.family}
                  </h2>
                  <h1 className="text-gray-900 text-3xl title-font font-medium mb-4">
                    {metadata.name}
                  </h1>
                </>
              )}
              {type === "WhiteList" && (
                <>
                  <h2 className="text-sm title-font text-gray-500 tracking-widest">
                    {metadata?.projectName}
                  </h2>
                  <h1 className="text-blue-900 text-3xl title-font font-medium mb-4">
                    {availableSpots} Whitelist Spots
                  </h1>
                </>
              )}
              <p className="text-gray-700  p-0 mb-5">
                {type === "NFT" ? (
                  <span className="inline-block px-2 py-1 leading-none bg-orange-200 text-orange-800 rounded-full font-semibold uppercase tracking-wide text-xs">
                    NFT RAFFLE
                  </span>
                ) : (
                  <span className="inline-block px-2 py-1 leading-none bg-blue-200 text-blue-800 rounded-full font-semibold uppercase tracking-wide text-xs">
                    WHITELIST RAFFLE
                  </span>
                )}
              </p>

              <div>
                <div className="flex items-center  border-gray-200 py-2">
                  <span className="text-gray-500">Status</span>
                  <span className="ml-auto text-gray-900">
                    <div>{getRaffleStatusText()}</div>
                  </span>
                </div>
                {isLive() ? (
                  <div className="flex items-center  border-gray-200 py-2">
                    <span className="text-gray-500">Time left</span>
                    <span className="ml-auto text-gray-900">
                      {solanaHealth?.disableRaffles ? (
                        "Paused"
                      ) : (
                        <Countdown
                          date={endDate.toDate()}
                          renderer={renderer}
                        />
                      )}
                    </span>
                  </div>
                ) : (
                  <div className="flex items-center  border-gray-200 py-2">
                    <span className="text-gray-500">Ended at</span>
                    <span className="ml-auto text-gray-900">
                      <div>
                        {moment(endDate.toDate()).format(
                          "MMMM Do YYYY, h:mm a"
                        )}
                      </div>
                    </span>
                  </div>
                )}

                <div className="flex items-center  border-gray-200 py-2">
                  <span className="text-gray-500">Tickets Sold</span>
                  <span className="ml-auto text-gray-900">
                    <div className="flex flex-row">
                      <b>{purchasedTickets}</b>/<div>{numOfTickets}</div>
                    </div>
                  </span>
                </div>
              </div>
              <Tabs
                defaultActiveKey={"1"}
                activeKey={activeKey}
                onChange={(e) => setActiveKey(e)}
              >
                {type === "WhiteList" && (
                  <TabPane tab="Project Info" key="3">
                    <div>
                      {type === "WhiteList" && (
                        <WhitelistInfo metadata={metadata} />
                      )}
                    </div>
                    <Button className="my-5" onClick={enterRaffle}>
                      ENTER RAFFLE
                    </Button>
                    <div className="block md:hidden">
                      <div className="mt-10">
                        {loadingTwitter && <Spin></Spin>}
                        <TwitterTimelineEmbed
                          options={{
                            height: "600px",
                          }}
                          onLoad={() => {
                            setLoadingTwitter(false);
                          }}
                          screenName={
                            metadata?.twitter.split("/")[
                              metadata?.twitter.split("/").length - 1
                            ]
                          }
                          sourceType="profile"
                        />
                      </div>
                    </div>
                  </TabPane>
                )}
                {isLive() && (
                  <TabPane tab="Enter Raffle" key="1">
                    <PurchaseTicketsForm
                      raffleDoc={raffleDoc}
                      onConnectWallet={handlePurchase}
                    />
                  </TabPane>
                )}
                {isLive() === false && (
                  <TabPane tab="Raffle Info" key="1">
                    <RaffleResults raffleDoc={raffleDoc} />
                  </TabPane>
                )}
                {isLive() && (
                  <TabPane tab="Participants" key="2">
                    <RaffleParticipants
                      activeKey={activeKey}
                      enterRaffle={enterRaffle}
                      raffleDoc={raffleDoc}
                    />
                  </TabPane>
                )}
              </Tabs>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export const NFTAttributes = ({ attributes }: any) => {
  if (attributes)
    return (
      <div className=" flex flex-wrap gap-3 my-3 justify-start overflow-hidden">
        {attributes.map(({ trait_type, value }: any) => (
          <Tag
            key={JSON.stringify({ trait_type, value })}
            color="cyan"
            className="overflow-hidden whitespace-nowrap text-ellipsis"
          >
            {trait_type} &nbsp;| &nbsp;
            <b>{value}</b>
          </Tag>
        ))}
      </div>
    );
  return <></>;
};

const Completionist = () => (
  <span style={{ fontSize: "13px" }}>RAFFLE ENDED</span>
);

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
  if (completed) {
    // Render a complete state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <div className="time-left">
        <div className="items-container">
          <div className="item-container">
            <div className="item-value">{days}</div>
            <div className="item-title">D</div>
          </div>
          <div className="countdown-divider"></div>
          <div className="item-container">
            <div className="item-value">{hours}</div>
            <div className="item-title">H</div>
          </div>
          <div className="countdown-divider"></div>
          <div className="item-container">
            <div className="item-value">{minutes}</div>
            <div className="item-title">M</div>
          </div>
          <div className="countdown-divider"></div>
          <div className="item-container">
            <div className="item-value">{seconds}</div>
            <div className="item-title">S</div>
          </div>
        </div>
      </div>
    );
  }
};

const WhitelistInfo: React.FC<any> = ({ metadata }) => {
  const {
    projectName,
    mintPrice,
    mintDate,
    mintSupply,
    description,
    projectUtilities,
    projectRoadmap,
    twitter,
    discordInviteLink,
  } = metadata;

  return (
    <div>
      <Linkify>
        <h1 className=" text-2xl text-blue-900">{projectName}</h1>
      </Linkify>

      <Descriptions size="small">
        <Descriptions.Item span={3} label="Mint Supply">
          <Linkify>
            <b> {mintSupply}</b>
          </Linkify>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Mint Price">
          <Linkify>
            <b> {mintPrice}</b>
          </Linkify>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Mint Date">
          <Linkify>
            <b> {mintDate}</b>
          </Linkify>
        </Descriptions.Item>
      </Descriptions>

      <h1 className=" text-2xl text-gray-500 mt-6 font-light">Description</h1>
      <p className="whitespace-pre-line">
        <Linkify>{description}</Linkify>
      </p>

      {projectUtilities && (
        <>
          <h1 className=" text-2xl text-gray-500 mt-6 font-light">
            Project Utilities
          </h1>
          <p className="whitespace-pre-line">
            <Linkify>{projectUtilities}</Linkify>
          </p>
        </>
      )}
      {projectRoadmap && (
        <>
          <h1 className=" text-2xl text-gray-500 mt-6 font-light">
            Project Roadmap
          </h1>
          <p className="whitespace-pre-line">
            <Linkify>{projectRoadmap}</Linkify>
          </p>
        </>
      )}

      <Descriptions size="small">
        <Descriptions.Item span={3} label="Twitter">
          <Linkify>
            <b> {twitter}</b>
          </Linkify>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Discord">
          <Linkify>
            <b> {discordInviteLink}</b>
          </Linkify>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};
