import { Tabs } from "antd";
import { useEffect, useState } from "react";
import { useFirebase } from "../../Auth/FirebaseService";
import "./Vaults.scss";
import firebase from "firebase";
import { GridLayout } from "../../Layout/GridLayout";
import {
  RaffleItem,
  RaffleItemSkeleton,
} from "../../Compontents/RaffleItem/RaffleItem";
import { useNavigate, useParams } from "react-router-dom";
import { AllVaults } from "./AllVaults/AllVaults";
import { VaultPortfolio } from "./AllVaults/VaultPortfolio";
import { Footer } from "../../Layout/Footer/Footer";

const { TabPane } = Tabs;

export const VaultsPage = () => {
  return (
    <>
      <div className=" relative">
        <div
          className="vaults-container text-gray-600 body-font overflow-hidden relative "
          style={{ height: "calc(100vh - 70px)", overflow: "auto" }}
        >
          <div className="container mx-auto">
            <div className="title-container m-auto py-7 mt-20">
              <div className="title">Vault Center</div>
              <div className="subtitle">
                Explore staking opportunities and manage your portfolio
              </div>
            </div>
            <Tabs
              className="block"
              defaultActiveKey="1"
              type="line"
              centered
              size={"large"}
              style={{ marginBottom: 32 }}
            >
              <TabPane tab="All Vaults" key="1">
                <AllVaults />
              </TabPane>

              <TabPane tab="My Portfolio" key="2">
                <VaultPortfolio />
              </TabPane>
            </Tabs>
          </div>
          <div className="flex h-96 items-center justify-center"></div>
          <Footer />
        </div>
      </div>
    </>
  );
};
