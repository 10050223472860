import "./App.scss";
import { FirebaseService } from "./Auth/FirebaseService";
import "antd/dist/antd.css";
import "antd/dist/antd.variable.min.css";
import { BrowserRouter } from "react-router-dom";
import { WalletService } from "./Services/WalletService/WalletService";
import { Routing } from "./Routing/Routing";
import { ConfigProvider } from "antd";
import { NavBar } from "./Layout/NavBar/NavBar";
import { OverlaysWrapper } from "./Overlays";
import { useEffect, useRef } from "react";
import { SigninService } from "./Auth/SigninService/SigninService";
import { ProcessPurchaseProvider } from "./Overlays/ProcessPurchaseService/ProcessPurchaseService";
import { SolanaHealthProvider } from "./Services/SolanaHealthService/SolanaHealthService";
import { Footer } from "./Layout/Footer/Footer";
// import ReactGA from "react-ga";

ConfigProvider.config({ theme: { primaryColor: "#62a770" } });
function App() {
  const ref = useRef<any>();
  useEffect(() => {
    // ReactGA.initialize("G-0TNNWJK3JH");
    // ReactGA.pageview(window.location.pathname + window.location.search);
    // ReactGA.event({
    //   category: 'User',
    //   action: 'Sent message'
    // });
  }, []);
  (window as any).scrollToTOP = () => {
    ref?.current?.scrollIntoView({
      // behavior: "",
      inline: "center",
    });
  };
  return (
    <div className="App">
      <BrowserRouter>
        <ConfigProvider>
          <FirebaseService>
            <SolanaHealthProvider>
              <SigninService>
                <WalletService>
                  <ProcessPurchaseProvider>
                    <OverlaysWrapper />
                    <div
                      ref={ref}
                      onScrollCapture={(e) => {
                        ref?.current?.scrollIntoView({
                          behavior: "smooth",
                          inline: "center",
                        });
                      }}
                    >
                      <NavBar />
                      {/* <div
                  className=" relative"
                  style={{ height: "calc(100vh - 70px)", overflow: "hidden" }}
                > */}
                      <Routing></Routing>
                      {/* </div> */}
                    </div>
                  </ProcessPurchaseProvider>
                </WalletService>
              </SigninService>
            </SolanaHealthProvider>
          </FirebaseService>
        </ConfigProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
