import "./Hero.scss";
import { Slogen } from "./Slogen/Slogen";
import Logo from "../../../../../assets/DV_favicon.jpeg";

export const Hero = () => {
  return (
    <>
      <div className="hero-main-container flex flex-col">
        <div className="slogen-con">
          <Slogen />
        </div>
      </div>
      {/* <div className="hero-text-container flex flex-col">
        <div className="hero-text">
          <b>Diamond Vaults</b> is the largest staking, raffles and utility
          platform on Solana.
        </div>
        <div>↓</div>
      </div> */}
    </>
  );
};
