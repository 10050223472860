/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import { useFirebase } from "../FirebaseService";
import firebase from "firebase/app";
import { Modal, Spin, notification } from "antd";
import { Button } from "react-daisyui";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

type SigninData = {
  setShowSigninModal?: any;
  getNavbarAuthCTA?: any;
  getCTAContainer?: any;
  loadingUserDoc?: any;
  userDoc?: any;
};

const discordRedirect = "https://www.diamondvaults.io/verify";
// const discordRedirect = "https://dv-client-staging.web.app/verify";
// const discordRedirect = "http://localhost:3000/verify";
const SigninContext = createContext<SigninData>({});

export function useSignin() {
  return useContext(SigninContext);
}

export const SigninService = ({ children }: any) => {
  const [showSigninModal, setShowSigninModal] = useState(false);
  const [signingIn, setSigningIn] = useState(false);
  const [connectingDiscord, setConnectingDiscord] = useState(false);
  const navigate = useNavigate();

  const [user, setUser] = useState<firebase.User | null>();
  const [userDoc, setUserDoc] =
    useState<
      firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
    >();
  const [loadingUser, setLoadingUser] = useState(true);
  const [loadingUserDoc, setLoadingUserDoc] = useState(false);
  const [loadingTwitterInfo, setLoadingTwitterInfo] = useState(false);
  const [loadingDiscordInfo, setLoadingDiscordInfo] = useState(false);
  const [hasTwitterInfo, setHasTwitterInfo] = useState(false);
  const [hasDiscordInfo, setHasDiscordInfo] = useState(false);

  const { auth, firestore, customFirebaseRequest } = useFirebase();

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      // console.log("NEW USER EVENT", user);
      setLoadingTwitterInfo(true);
      setLoadingDiscordInfo(true);
      setLoadingUserDoc(true);
      setUser(user);
      setLoadingUser(false);
    });
  }, []);

  useEffect(() => {
    // console.log("GET USER DOC SNAP", user);
    setLoadingUserDoc(true);
    if (user && !signingIn) {
      // alert(`ATwitterUser/${user.uid}`);
      return firestore.doc(`ATwitterUser/${user.uid}`).onSnapshot((doc) => {
        // console.log("THIS USER DOC", doc, doc.exists);
        // alert(`YO`);
        if (doc.exists) {
          setUserDoc(doc);
          setLoadingUserDoc(false);
        }
        // else if (!signingIn) {
        //   auth.signOut().then();
        // }
      });
    }
  }, [user, signingIn]);

  useEffect(() => {
    setLoadingTwitterInfo(true);
    setLoadingDiscordInfo(true);
    setHasTwitterInfo(false);
    setHasDiscordInfo(false);
    if (userDoc) {
      const { discordInfo, twitterInfo } = userDoc.data()!;
      setHasTwitterInfo(!!twitterInfo);
      setHasDiscordInfo(!!discordInfo);
    }
    setLoadingTwitterInfo(false);
    setLoadingDiscordInfo(false);
  }, [userDoc]);

  const [params] = useSearchParams();
  const [code, setCode] = useState<any>();

  useEffect(() => {
    // console.log(params.get("code"));
    if (params.get("code") && user) {
      setCode(params.get("code"));
    } else {
      setCode(undefined);
    }
  }, [params, user]);
  useEffect(() => {
    if (code && user && userDoc && hasTwitterInfo) {
      setShowSigninModal(true);
      confirmDiscord();
    }
  }, [code, hasTwitterInfo]);

  const confirmDiscord = async () => {
    if (user) {
      setConnectingDiscord(true);
      const userIdToken = await user?.getIdToken(true);
      const res = await customFirebaseRequest(
        "SOCIAL_V1_VerifyDiscordAccount",
        {
          idToken: userIdToken,
          payload: {
            code,
            redirectUri: discordRedirect,
          },
        }
      );
      notification["success"]({
        message: "Welcome! All features are unlocked",
        placement: "top",
      });
      setConnectingDiscord(false);
      navigate("/raffles");
    }
  };
  const getContent = () => {
    if (showSigninModal) {
      // // console.log("Enter");
      if (!loadingUser) {
        // // console.log("loaded user");
        if (!signingIn) {
          // // console.log("not signing in");
          if (!connectingDiscord) {
            // // console.log("not connecting discord");
            if (user) {
              // // console.log("user us logged in");
              // // console.log({ loadingUserDoc, userDoc });

              if (!loadingUserDoc) {
                // // console.log("Loaded user doc");
                if (userDoc) {
                  // // console.log("has user doc");
                  if (!loadingTwitterInfo) {
                    // // console.log("leaded twitter info");
                    if (hasTwitterInfo) {
                      // // console.log("has twitter info");
                      if (!loadingDiscordInfo) {
                        // // console.log("loaded discord info");
                        if (hasDiscordInfo) {
                          // // console.log("has discord info ");
                          navigate("/raffles");
                          setShowSigninModal(false);
                          return <>DONE</>;
                        } else {
                          // // console.log("missing discord info ");#7289da
                          return (
                            <div className="flex flex-col justify-center items-center">
                              <div style={{ fontSize: "17px" }}>
                                Link your discord account to use our features
                              </div>
                              <div
                                style={{ color: "#919191", fontSize: "12px" }}
                              >
                                You can revoke at anytime
                              </div>
                              <Button
                                disabled={signingIn}
                                onClick={connectDiscord}
                                color="primary"
                                style={{
                                  color: "white",
                                  marginTop: "31px",
                                  fontSize: "12px",
                                  border: "none",
                                  background: "#7289da",
                                  textTransform: "capitalize",
                                }}
                              >
                                <svg
                                  fill="#FFFFFF"
                                  width="17px"
                                  height="17px"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M18.942 5.556a16.299 16.299 0 0 0-4.126-1.297c-.178.321-.385.754-.529 1.097a15.175 15.175 0 0 0-4.573 0 11.583 11.583 0 0 0-.535-1.097 16.274 16.274 0 0 0-4.129 1.3c-2.611 3.946-3.319 7.794-2.965 11.587a16.494 16.494 0 0 0 5.061 2.593 12.65 12.65 0 0 0 1.084-1.785 10.689 10.689 0 0 1-1.707-.831c.143-.106.283-.217.418-.331 3.291 1.539 6.866 1.539 10.118 0 .137.114.277.225.418.331-.541.326-1.114.606-1.71.832a12.52 12.52 0 0 0 1.084 1.785 16.46 16.46 0 0 0 5.064-2.595c.415-4.396-.709-8.209-2.973-11.589zM8.678 14.813c-.988 0-1.798-.922-1.798-2.045s.793-2.047 1.798-2.047 1.815.922 1.798 2.047c.001 1.123-.793 2.045-1.798 2.045zm6.644 0c-.988 0-1.798-.922-1.798-2.045s.793-2.047 1.798-2.047 1.815.922 1.798 2.047c0 1.123-.793 2.045-1.798 2.045z" />
                                </svg>
                                &nbsp;&nbsp; Verify with Discord
                              </Button>
                            </div>
                          );
                        }
                      } else {
                        // // console.log("loading discord info ");
                        return <Spin></Spin>;
                      }
                    } else {
                      // // console.log("missing twitter info ");
                      // auth.signOut().then();
                    }
                  } else {
                    // // console.log("loading twitter info ");
                    return <Spin></Spin>;
                  }
                } else {
                  // // console.log("missing user doc info ");
                  // auth.signOut().then();
                }
              } else {
                // // console.log("loading user doc info ");
                return <Spin></Spin>;
              }
            } else {
              // // console.log("not logged in ");

              return (
                <div className="flex flex-col justify-center items-center">
                  <div style={{ fontSize: "17px" }}>
                    To confirm you are Human, please verify.
                  </div>
                  <div style={{ color: "#919191", fontSize: "12px" }}>
                    You can revoke at anytime
                  </div>
                  <Button
                    disabled={signingIn}
                    onClick={startSignin}
                    color="primary"
                    style={{
                      color: "white",
                      marginTop: "31px",
                      fontSize: "12px",
                      border: "none",
                      background: "#1DA1F2",
                      textTransform: "capitalize",
                    }}
                  >
                    <svg
                      fill="#FFFFFF"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 30 30"
                      width="17px"
                      height="17px"
                    >
                      <path d="M28,6.937c-0.957,0.425-1.985,0.711-3.064,0.84c1.102-0.66,1.947-1.705,2.345-2.951c-1.03,0.611-2.172,1.055-3.388,1.295 c-0.973-1.037-2.359-1.685-3.893-1.685c-2.946,0-5.334,2.389-5.334,5.334c0,0.418,0.048,0.826,0.138,1.215 c-4.433-0.222-8.363-2.346-10.995-5.574C3.351,6.199,3.088,7.115,3.088,8.094c0,1.85,0.941,3.483,2.372,4.439 c-0.874-0.028-1.697-0.268-2.416-0.667c0,0.023,0,0.044,0,0.067c0,2.585,1.838,4.741,4.279,5.23 c-0.447,0.122-0.919,0.187-1.406,0.187c-0.343,0-0.678-0.034-1.003-0.095c0.679,2.119,2.649,3.662,4.983,3.705 c-1.825,1.431-4.125,2.284-6.625,2.284c-0.43,0-0.855-0.025-1.273-0.075c2.361,1.513,5.164,2.396,8.177,2.396 c9.812,0,15.176-8.128,15.176-15.177c0-0.231-0.005-0.461-0.015-0.69C26.38,8.945,27.285,8.006,28,6.937z" />
                    </svg>
                    &nbsp;&nbsp; Verify with Twitter
                  </Button>
                </div>
              );
            }
          } else {
            // // console.log("busy connecting discord ");
            return (
              <div className="flex flex-col justify-center items-center">
                <div style={{ fontSize: "17px" }}>
                  Link your discord account to use our features
                </div>
                <div style={{ color: "#919191", fontSize: "12px" }}>
                  You can revoke at anytime
                </div>
                <Spin style={{ marginTop: "31px" }} />
              </div>
            );
          }
        } else {
          // // console.log("busy signing in ");
          return (
            <div className="flex flex-col justify-center items-center">
              <div style={{ fontSize: "17px" }}>
                To confirm you are Human, please verify.
              </div>
              <div style={{ color: "#919191", fontSize: "12px" }}>
                You can revoke at anytime
              </div>
              <Spin style={{ marginTop: "31px" }} />
            </div>
          );
          // return <Spin></Spin>;
        }
      } else {
        // // console.log("loading user ");
        return <Spin></Spin>;
      }
    }
  };

  const getNavbarAuthCTA = () => {
    // console.log("Enter");
    if (!loadingUser) {
      // console.log("loaded user");
      if (!signingIn) {
        // console.log("not signing in");
        if (!connectingDiscord) {
          // console.log("not connecting discord");
          if (user) {
            // console.log("user us logged in");
            // console.log({ loadingUserDoc, userDoc });

            if (!loadingUserDoc) {
              // console.log("Loaded user doc");
              if (userDoc) {
                // console.log("has user doc");
                if (!loadingTwitterInfo) {
                  // console.log("leaded twitter info");
                  if (hasTwitterInfo) {
                    // console.log("has twitter info");
                    if (!loadingDiscordInfo) {
                      // console.log("loaded discord info");
                      if (hasDiscordInfo) {
                        return (
                          <Button className="btn-ghost btn-circle">
                            <Link to={"/profile"}>
                              {userDoc?.data()?.twitterInfo
                                ?.profile_image_url_https ? (
                                <div className="w-10 rounded-full">
                                  <img
                                    alt=""
                                    className="rounded-full"
                                    src={
                                      userDoc?.data()?.twitterInfo
                                        .profile_image_url_https
                                    }
                                  />
                                </div>
                              ) : (
                                <svg
                                  version="1.1"
                                  id="Layer_1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  x="0px"
                                  y="0px"
                                  className="w-10 h-10 fill-gray-600"
                                  viewBox="0 0 459 459"
                                >
                                  <g>
                                    <g>
                                      <path
                                        d="M229.5,0C102.53,0,0,102.845,0,229.5C0,356.301,102.719,459,229.5,459C356.851,459,459,355.815,459,229.5
			C459,102.547,356.079,0,229.5,0z M347.601,364.67C314.887,393.338,273.4,409,229.5,409c-43.892,0-85.372-15.657-118.083-44.314
			c-4.425-3.876-6.425-9.834-5.245-15.597c11.3-55.195,46.457-98.725,91.209-113.047C174.028,222.218,158,193.817,158,161
			c0-46.392,32.012-84,71.5-84c39.488,0,71.5,37.608,71.5,84c0,32.812-16.023,61.209-39.369,75.035
			c44.751,14.319,79.909,57.848,91.213,113.038C354.023,354.828,352.019,360.798,347.601,364.67z"
                                      />
                                    </g>
                                  </g>
                                </svg>
                              )}
                            </Link>
                          </Button>
                        );
                      } else {
                        return (
                          <Button
                            disabled={signingIn}
                            onClick={(e) => setShowSigninModal(true)}
                            color="primary"
                            style={{ color: "white" }}
                          >
                            FINISH
                          </Button>
                        );
                      }
                    } else {
                      // console.log("loading discord info ");
                      return <Spin></Spin>;
                    }
                  } else {
                    // console.log("missing twitter info ");
                    auth.signOut().then();
                  }
                } else {
                  // console.log("loading twitter info ");
                  return <Spin></Spin>;
                }
              } else {
                // console.log("missing user doc info ");
                auth.signOut().then();
              }
            } else {
              // console.log("loading user doc info ");
              return <Spin></Spin>;
            }
          } else {
            // console.log("not logged in ");

            return (
              <Button
                disabled={signingIn}
                onClick={(e) => setShowSigninModal(true)}
                color="primary"
                style={{ color: "white" }}
              >
                LOGIN
              </Button>
            );
          }
        } else {
          // console.log("busy connecting discord ");
          return <Spin></Spin>;
        }
      } else {
        // console.log("busy signing in ");
        return <Spin></Spin>;
      }
    } else {
      // console.log("loading user ");
      return <Spin></Spin>;
    }
  };

  const getCTAContainer = (children: any) => {
    // console.log("Enter");
    if (!loadingUser) {
      // console.log("loaded user");
      if (!signingIn) {
        // console.log("not signing in");
        if (!connectingDiscord) {
          // console.log("not connecting discord");
          if (user) {
            // console.log("user us logged in");
            // console.log({ loadingUserDoc, userDoc });

            if (!loadingUserDoc) {
              // console.log("Loaded user doc");
              if (userDoc) {
                // console.log("has user doc");
                if (!loadingTwitterInfo) {
                  // console.log("leaded twitter info");
                  if (hasTwitterInfo) {
                    // console.log("has twitter info");
                    if (!loadingDiscordInfo) {
                      // console.log("loaded discord info");
                      if (hasDiscordInfo) {
                        return children;
                      } else {
                        // console.log("missing discord info ");
                        return (
                          <Button
                            disabled={signingIn}
                            onClick={(e) => setShowSigninModal(true)}
                            color="primary"
                            className="text-white w-full mt-3"
                            style={{ color: "white" }}
                          >
                            FINISH SIGNUP
                          </Button>
                        );
                      }
                    } else {
                      // console.log("loading discord info ");
                      return <Spin></Spin>;
                    }
                  } else {
                    // console.log("missing twitter info ");
                    auth.signOut().then();
                  }
                } else {
                  // console.log("loading twitter info ");
                  return <Spin></Spin>;
                }
              } else {
                // console.log("missing user doc info ");
                // auth.signOut().then();
              }
            } else {
              // console.log("loading user doc info ");
              return <Spin></Spin>;
            }
          } else {
            // console.log("not logged in ");

            return (
              <Button
                disabled={signingIn}
                onClick={(e) => setShowSigninModal(true)}
                color="primary"
                className="text-white w-full mt-3"
                style={{ color: "white" }}
              >
                LOGIN TO ENTER
              </Button>
            );
          }
        } else {
          // console.log("busy connecting discord ");
          return <Spin></Spin>;
        }
      } else {
        // console.log("busy signing in ");
        return <Spin></Spin>;
      }
    } else {
      // console.log("loading user ");
      return <Spin></Spin>;
    }
  };

  const startSignin = async () => {
    await signupWithTwitter();
  };

  useEffect(() => {
    firebase
      .auth()
      .getRedirectResult()
      .then(async (result) => {
        if (result.credential) {
          setLoadingTwitterInfo(true);
          setLoadingDiscordInfo(true);
          // setLoadingUserState(true);
          setLoadingUserDoc(true);
          setSigningIn(true);
          setShowSigninModal(true);

          /** @type {firebase.auth.OAuthCredential} */
          const credential: any = result.credential;
          // console.log("result.user", result.user);

          // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
          // You can use these server side with your app's credentials to access the Twitter API.
          // console.log(credential);

          const token = credential.accessToken;
          const secret = credential.secret;

          // The signed-in user info.
          const user = result.user;
          const userIdToken = await user?.getIdToken(true);
          const res = await customFirebaseRequest(
            "SOCIAL_V1_CreateTwitterUser",
            {
              idToken: userIdToken,
              payload: {
                displayName: user?.displayName,
                token,
                secret,
              },
            }
          );
          // console.log(res);
          // alert(JSON.stringify(res));
          const userDoc = await firestore
            .doc(`ATwitterUser/${user?.uid}`)
            .get();
          // alert(userDoc.ref.path);
          if (userDoc.exists) {
            setUserDoc(userDoc);
          }

          // alert(userDoc.exists);
          setSigningIn(false);

          // window.location.href =
          //   "https://discord.com/api/oauth2/authorize?client_id=915708397024854026&redirect_uri=http://localhost:3002/verify&response_type=code&scope=identify";
          console.log({
            token,
            secret,
            user: user?.uid,
            name: user?.displayName,
            userIdToken,
          });
        } else {
          // console.log("User did not redirect");
        }
      })
      .catch((err) => {
        // console.error(err);
      });
  }, []);

  const signupWithTwitter = () =>
    new Promise<any>((resolve) => {
      setSigningIn(true);
      // setLoadingUserState(true);
      setLoadingUserDoc(true);
      const twitterProvider = new firebase.auth.TwitterAuthProvider();
      firebase.auth().useDeviceLanguage();
      firebase
        .auth()
        .signInWithRedirect(twitterProvider)
        .then(() => {
          // console.log("Ridirecting to login");
        })
        .catch((error) => {
          // console.log(error);

          setSigningIn(false);
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.email;
          // The firebase.auth.AuthCredential type that was used.
          const credential = error.credential;
          resolve(false);
          // ...
        });
    });

  const connectDiscord = () => {
    window.location.href = `https://discord.com/api/oauth2/authorize?client_id=915708397024854026&redirect_uri=${discordRedirect}&response_type=code&scope=identify`;
  };

  const getDiscordLoggedIn = () => {
    // console.log(userDoc);

    if (userDoc) {
      const { discordInfo } = userDoc.data()!;
      // User is registered
      // console.log({ discordInfo });

      if (discordInfo) {
        // User is registered
        return true;
      } else {
        // User is missing discord
        return false;
      }
    }
    return false;
  };
  const value: SigninData = {
    setShowSigninModal,
    getNavbarAuthCTA,
    getCTAContainer,
    loadingUserDoc,
    userDoc,
  };

  return (
    <SigninContext.Provider value={value}>
      <Modal
        // title="Join the revolution "
        centered
        zIndex={9000}
        visible={showSigninModal}
        footer={""}
        closable
        onCancel={() => setShowSigninModal(false)}
      >
        {showSigninModal && getContent()}
        {/* {!loadingUserState && !loadingUseDocrState && (
          <Steps progressDot direction="vertical" current={1}>
            <Step
              title="Login with Twitter"
              status={
                user && !loadingUserState && userDoc && !loadingUseDocrState
                  ? "finish"
                  : "process"
              }
              description={
                user && !loadingUserState && userDoc && !loadingUseDocrState ? (
                  <div>Login done</div>
                ) : (
                  <div className="flex flex-col">
                    <Button
                      disabled={signingIn}
                      onClick={signupWithTwitter}
                      color="primary"
                      style={{ color: "white" }}
                    >
                      LOGIN
                    </Button>
                  </div>
                )
              }
            />

            {user && !loadingUserState && userDoc && !loadingUseDocrState && (
              <Step
                title="Connect discord"
                status={
                  user &&
                  !loadingUserState &&
                  userDoc &&
                  !loadingUseDocrState &&
                  getDiscordLoggedIn()
                    ? "finish"
                    : "process"
                }
                description={
                  user &&
                  !loadingUserState &&
                  userDoc &&
                  !loadingUseDocrState &&
                  getDiscordLoggedIn() ? (
                    <div>Login done</div>
                  ) : (
                    <div className="flex flex-col">
                      <Button
                        disabled={signingIn}
                        onClick={connectDiscord}
                        color="primary"
                        style={{ color: "white" }}
                      >
                        CONNECT DISCORD
                      </Button>
                    </div>
                  )
                }
              />
            )}
          </Steps>
        )} */}
      </Modal>

      {children}
    </SigninContext.Provider>
  );
};
