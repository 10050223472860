import { Col, Divider, Input, List, Row, Statistic, Table, Tabs } from "antd";
import { useEffect, useState } from "react";
import firebase from "firebase";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useFirebase } from "../../../Auth/FirebaseService";
import { ColumnsType } from "antd/lib/table";
import { GridLayout } from "../../../Layout/GridLayout";
import { VaultProjectItem } from "../../../Compontents/VaultProjectItem/VaultProjectItem";

export const AllVaults = () => {
  const { firestore, user, loadingUserState } = useFirebase();
  const [setup, setSetup] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const [leaderboard, setLeaderboard] = useState<any[]>();
  const [filtered, setFiltered] = useState<any>();
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    if (!setup) {
      console.log("in");
      getVaultsLeaderboard();
      setSetup(true);
    }
  }, [setup]);

  useEffect(() => {
    if (leaderboard) {
      if (search?.length > 0) {
        return setFiltered(
          [...leaderboard].filter(({ VaultName }: { VaultName: string }) => {
            return VaultName.toLocaleLowerCase().includes(
              search.toLocaleLowerCase()
            );
          })
        );
      }
      return setFiltered([...leaderboard]);
    }
    return setFiltered(undefined);
  }, [leaderboard, search]);

  const getVaultsLeaderboard = async () => {
    const doc = await firestore.doc(`/AOverallStats/AllVaultLeaderboard`).get();
    const { LeaderboardArray } = doc.data()!;
    setLeaderboard(
      LeaderboardArray.filter(({ TotalStaked }: any) => TotalStaked > 30).sort(
        (a: any, b: any) => b.FloorPrice - a.FloorPrice
      )
    );
  };

  return (
    <div>
      <div className="w-96 m-auto">
        <Input
          placeholder="Find a vault by name"
          onChange={(e) => setSearch(e.target.value)}
          prefix={<SearchOutlined className="site-form-item-icon" />}
          // suffix={
          //   <Tooltip title="Extra information">
          //     <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
          //   </Tooltip>
          // }
        />
      </div>
      <GridLayout>
        {filtered &&
          filtered.map((item: any) => {
            return (
              <VaultProjectItem
                key={item.VaultRoute}
                vaultData={item}
                type="grid"
              />
            );
          })}
      </GridLayout>
    </div>
  );
};
