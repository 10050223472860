import "./ExploreProjectsPage.scss";
import { Drawer } from "react-daisyui";
import { useEffect, useState } from "react";
import { PageHeader, Tooltip, Button, Input, Divider } from "antd";
import { GridLayout } from "../../Layout/GridLayout";
import { FilterFilled, CheckCircleOutlined } from "@ant-design/icons";
import { useFirebase } from "../../Auth/FirebaseService";
import firebase from "firebase";
import { useNavigate, useParams } from "react-router-dom";

import { RadioGroup } from "@headlessui/react";
import moment from "moment";
import {
  ProjectItem,
  ProjectItemSkeleton,
} from "../../Compontents/ProjectItem/ProjectItem";
import { Footer } from "../../Layout/Footer/Footer";

const { Search } = Input;

const filters = [
  {
    name: "Recently joined",
    disabled: false,
    desc: "See all the projects that have joined Diamond Vaults",
    action: (
      query: firebase.firestore.Query<firebase.firestore.DocumentData>
    ) => query.where("verified", "==", true).orderBy("verifiedDate", "desc"),
    orderBys: [],
  },
  {
    name: "Book Burner",
    disabled: true,
    desc: "Coming soon",
    action: (
      query: firebase.firestore.Query<firebase.firestore.DocumentData>
    ) => query.where("verified", "==", true).orderBy("bookBurnerSize", "desc"),
    orderBys: [],
  },
];

export const ExploreProjectsPage = () => {
  const [visible, setVisible] = useState(false);
  const { firestore } = useFirebase();
  const [setup, setSetup] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [selected, setSelected] = useState(filters[0]);
  const [orderBy, setOrderBy] = useState(filters[0].orderBys[0]);

  const toggleVisible = () => {
    setVisible(!visible);
  };
  const [raffles, setRaffles] =
    useState<
      firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]
    >();

  useEffect(() => {
    setRaffles(undefined);
    if (selected) {
      let query = selected.action(firestore.collectionGroup(`Profile`));
      query
        .get()
        .then(({ docs }) => {
          setRaffles(docs);
        })
        .catch((err) => {
          console.error("getRecentlyEndingSoonRaffles", err);
        });
    }
  }, [selected, orderBy]);

  const args: any = {};
  return (
    <Drawer
      {...args}
      open={visible}
      end
      onClickOverlay={toggleVisible}
      side={
        <div className=" bg-gray-200 w-72 px-4">
          <div
            style={{
              padding: "20px",
              fontWeight: 300,
              letterSpacing: "3px",
              color: "#4a8856",
              fontSize: "18px",
            }}
          >
            Filter by
          </div>
          <RadioGroup
            value={selected}
            onChange={(e: any) => {
              setSelected(e);
              setOrderBy(e.orderBys[0] || undefined);
            }}
          >
            <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
            <div className="space-y-2">
              {filters.map((plan) => (
                <RadioGroup.Option
                  disabled={plan.disabled}
                  key={plan.name}
                  value={plan}
                  style={{ opacity: plan.disabled ? 0.6 : 1 }}
                  className={({ active, checked }) =>
                    `${
                      active
                        ? "ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-300"
                        : ""
                    }
                    ${
                      checked
                        ? " bg-green-700 bg-opacity-75 text-white"
                        : "bg-white"
                    }
                      relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
                  }
                >
                  {({ active, checked }) => (
                    <>
                      <div className="flex w-full items-center justify-between">
                        <div className="flex items-center">
                          <div className="text-sm">
                            <RadioGroup.Label
                              as="p"
                              className={`font-medium  ${
                                checked ? "text-white" : "text-gray-900"
                              }`}
                            >
                              {plan.name}
                            </RadioGroup.Label>
                            <RadioGroup.Description
                              as="span"
                              className={`inline ${
                                checked ? "text-sky-100" : "text-gray-500"
                              }`}
                            >
                              <span>{plan.desc}</span>
                            </RadioGroup.Description>
                          </div>
                        </div>
                        {checked && (
                          <div className="shrink-0 text-white">
                            <CheckCircleOutlined className="h-6 w-6" />
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        </div>
      }
    >
      <div className="sticky top-0 explore-container-header-container">
        <div className="lg:w-3/4 mx-auto  align-middle ">
          <PageHeader
            className="site-page-header-responsive"
            title={<div className="all-projects-title">PROJECTS</div>}
            extra={[
              // <Tooltip title="Filter">
              <Button
                onClick={toggleVisible}
                type="primary"
                shape="circle"
                size="large"
                icon={<FilterFilled />}
              />,
              // </Tooltip>,
            ]}
          ></PageHeader>
        </div>
      </div>
      <GridLayout>
        {raffles?.map((doc) => (
          <ProjectItem key={doc.id} projectDoc={doc} type="grid" />
        ))}
        {!raffles &&
          [1].map(() => {
            return <ProjectItemSkeleton />;
          })}
      </GridLayout>
      <div className="flex h-36 items-center justify-center"></div>
      <Footer />
    </Drawer>
  );
};
