import { useWallet } from "@solana/wallet-adapter-react";
import { InputNumber, Radio, Space, Divider, message, Tag } from "antd";
import { Button } from "react-daisyui";
import firebase from "firebase";
import { useEffect, useState } from "react";
import { sendLocalTransactions } from "../../../Web3/TransactionBuilder";
import { Transaction } from "@solana/web3.js";
import { useRaffleStatus } from "../../../Hooks/useRaffleStatus";
import { buyRaffleTickets } from "../../../API/Raffles/BuyRaffleTicket";
import { useFirebase } from "../../../Auth/FirebaseService";
import { checkWalletBalance } from "../../../Web3/BalanceChecker";
import { useProcessPurchase } from "../../ProcessPurchaseService/ProcessPurchaseService";
import { useSignin } from "../../../Auth/SigninService/SigninService";
import { useSolanaHealth } from "../../../Services/SolanaHealthService/SolanaHealthService";
import { useCommunityRoles } from "../../../Hooks/useCommunityRoles";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
type PurchaseTicketsFormProps = {
  onConnectWallet: any;
  raffleDoc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>;
};

export const PurchaseTicketsForm: React.FC<PurchaseTicketsFormProps> = ({
  onConnectWallet,
  raffleDoc,
}) => {
  const { solanaHealth } = useSolanaHealth();
  const { addPurchaseListener } = useProcessPurchase();
  const { getCTAContainer } = useSignin();
  const { publicKey, signTransaction } = useWallet();
  const [currency, setCurrency] = useState(1);
  const [tickets, setTickets] = useState(1);
  const raffleData = raffleDoc?.data();
  const { user } = useFirebase();
  const [busyBuying, setBufyBuying] = useState(false);
  const raffleStatus = useRaffleStatus(raffleDoc);
  const isLive = () => raffleStatus === "LIVE" || raffleStatus === "ENDING";
  const { loadingRoles, matchingRoles, canPurchase } =
    useCommunityRoles(raffleDoc);

  useEffect(() => {
    console.log({ loadingRoles, matchingRoles, canPurchase });
  }, [loadingRoles, matchingRoles]);

  if (!raffleDoc || !raffleData) return <></>;

  const {
    numOfTickets,
    token,
    ticketPrice,
    type,
    purchasedTickets,
    ownerWalletAddress,
    configWalletAddress,
    bookBurner,
    buyers,
    roles,
  } = raffleData;

  const finishedLoadingRoles = () => !loadingRoles && matchingRoles;

  const onChange = (newValue: number) => {
    setTickets(newValue);
  };

  const getPriceText = () => {
    const tokenName = currency === 1 ? token.name : "SOL";
    const price =
      tickets * (currency === 1 ? ticketPrice : bookBurner.ticketPrice);
    return `${price.toLocaleString()} $${tokenName}`;
  };
  const handlePurchase = async () => {
    console.log({ publicKey, signTransaction });

    if (publicKey && signTransaction && user) {
      setBufyBuying(true);
      const hide = message.loading("Busy creating your transaction... ", 0);

      let hasEnough = false;
      const SOLTransfers: {
        fromAddress: string;
        toAddress: string;
        solAmount: number;
      }[] = [];
      const SPLTransfers: {
        fromAddress: string;
        toAddress: string;
        tokenAddress: string;
        amount: number;
      }[] = [];

      // Transaction Fee Configuration
      // const solTicketPrice = type === "NFT" ? 0.001 : 0.0005;
      const solTicketPrice = 0;
      const transactionFee = solTicketPrice * tickets;
      const commision = 0;
      const projectCut = 0.4;

      // To Project
      SOLTransfers.push({
        fromAddress: publicKey.toString(),
        toAddress: configWalletAddress,
        solAmount: (1 - projectCut) * transactionFee,
      });
      // To DV
      SOLTransfers.push({
        fromAddress: publicKey.toString(),
        toAddress: ownerWalletAddress,
        solAmount: projectCut * transactionFee,
      });
      // Payment
      if (currency === 1) {
        // SPL / SOL

        if (token.address === "So11111111111111111111111111111111111111112") {
          // SOL
          // Recalculate the sol price without decimal
          const price = tickets * ticketPrice;
          // To Project
          SOLTransfers.push({
            fromAddress: publicKey.toString(),
            toAddress: ownerWalletAddress,
            solAmount: price * (1 - commision),
          });
          if (price * commision > 0) {
            // To DV
            SOLTransfers.push({
              fromAddress: publicKey.toString(),
              toAddress: configWalletAddress,
              solAmount: price * commision,
            });
          }
          hasEnough = await checkWalletBalance({
            walletAddress: publicKey.toString(),
            solAmount: transactionFee + price,
          });
        } else {
          // SOL
          const decimal = token.decimalPoints;
          const price = tickets * ticketPrice * decimal;
          // To Project
          SPLTransfers.push({
            fromAddress: publicKey.toString(),
            toAddress: ownerWalletAddress,
            amount: price * (1 - commision),
            tokenAddress: token.address,
          });

          if (price * commision > 0) {
            // To DV
            SPLTransfers.push({
              fromAddress: publicKey.toString(),
              toAddress: configWalletAddress,
              amount: price * commision,
              tokenAddress: token.address,
            });
          }
          hasEnough = await checkWalletBalance({
            walletAddress: publicKey.toString(),
            solAmount: transactionFee,
            tokens: [
              {
                name: token.name,
                address: token.address,
                amount: tickets * ticketPrice,
              },
            ],
          });
        }
      } else {
        // SOL
        const price = tickets * bookBurner.ticketPrice;
        // To Book Burner
        SOLTransfers.push({
          fromAddress: publicKey.toString(),
          toAddress: bookBurner.walletAddress,
          solAmount: price * (1 - commision),
        });
        if (price * commision > 0) {
          // To DV
          SOLTransfers.push({
            fromAddress: publicKey.toString(),
            toAddress: configWalletAddress,
            solAmount: price * commision,
          });
        }

        hasEnough = await checkWalletBalance({
          walletAddress: publicKey.toString(),
          solAmount: transactionFee + price,
        });
      }

      if (hasEnough) {
        try {
          const transaction = (await sendLocalTransactions(
            SOLTransfers,
            SPLTransfers,
            publicKey.toString(),
            signTransaction,
            (update, message) => {
              console.log(update, message);
            },
            true
          )) as Transaction;

          const { data } = await buyRaffleTickets(
            await user.getIdToken(),
            raffleDoc.id,
            {
              bookBurner: currency !== 1,
              numTickets: tickets,
              trans: transaction.serialize(),
              walletAddress: publicKey.toString(),
            }
          );
          if (data?.buyTicketRequest) {
            hide();
            await addPurchaseListener!(data.buyTicketRequest);
          } else {
            hide();
          }
        } catch (err) {
          console.error(err);
          hide();
        }
      } else {
        hide();
      }
    } else {
      onConnectWallet();
    }
    setBufyBuying(false);
  };

  return (
    <>
      {/* <Slider
        min={1}
        max={20}
        // tooltipVisible
        dots
        tooltipPlacement="left"
        onChange={onChange}
        value={typeof tickets === "number" ? tickets : 0}
      /> */}

      <div className="flex items-center  border-gray-200 py-2">
        <span className="text-gray-500">Quantity</span>
        <span className="ml-auto text-gray-900">
          <InputNumber
            addonAfter="Tickets"
            min={1}
            disabled={!isLive()}
            max={numOfTickets - purchasedTickets}
            style={{ margin: "0 16px", width: 140 }}
            value={tickets}
            onChange={onChange}
          />
        </span>
      </div>
      <Divider></Divider>
      <div className="flex items-center  border-gray-200 py-2">
        <span className="text-gray-500">Ticket ($)</span>
        <span className="ml-auto text-gray-900">
          <Radio.Group
            // buttonStyle="solid"
            disabled={!isLive()}
            value={currency}
            onChange={(e) => setCurrency(e.target.value)}
          >
            <Space direction="vertical">
              {token && (
                <Radio value={1}>
                  <b className=" ">
                    {ticketPrice.toLocaleString()} ${token.name}
                  </b>{" "}
                  / Ticket
                </Radio>
              )}
              {bookBurner && (
                <Radio value={2}>
                  <b className=" ">
                    {bookBurner.ticketPrice.toLocaleString()} $SOL
                  </b>{" "}
                  / Ticket
                </Radio>
              )}
            </Space>
          </Radio.Group>
        </span>
      </div>
      {buyers === "holders" && (
        <>
          <Divider></Divider>
          <div className=" grid items-center  border-gray-200 py-2">
            <h3 className="text-gray-500">Community Raffle</h3>
            <span className="text-gray-500">
              This raffle is available only to users that have any of the
              following roles on the community's discord.
            </span>
            <div className=" text-gray-900 mt-3 flex flex-wrap ">
              {(!matchingRoles || loadingRoles) &&
                roles &&
                roles.map(({ name, id }: any) => {
                  return (
                    <div className="my-1">
                      <Tag
                        icon={user ? <SyncOutlined spin /> : <></>}
                        color="default"
                      >
                        {name}
                      </Tag>
                    </div>
                  );
                })}
              {matchingRoles &&
                roles &&
                roles.map(({ name, id }: any) => {
                  if (matchingRoles.some((role: any) => role.id === id)) {
                    return (
                      <div className="my-1">
                        <Tag icon={<CheckCircleOutlined />} color="#57A780">
                          {name}
                        </Tag>
                      </div>
                    );
                  }
                  return (
                    <div className="my-1">
                      <Tag icon={<CloseCircleOutlined />} color="#f50">
                        {name}
                      </Tag>
                    </div>
                  );
                })}
            </div>
          </div>
        </>
      )}
      <Divider></Divider>
      <div className="flex items-center  border-gray-200 py-2">
        <span className="text-gray-500 font-bold">Total</span>
        <span className="ml-auto text-gray-900">
          <div>
            <div className=" text-gray-400">{tickets} Tickets</div>
            <div className=" text-xl font-extrabold">{getPriceText()}</div>
          </div>
        </span>
      </div>
      <div>
        {solanaHealth?.disableRaffles ? (
          <Button
            disabled={true}
            style={{ color: "red" }}
            className="text-white w-full mt-3"
          >
            PAUSED
          </Button>
        ) : (
          <>
            {isLive() &&
              getCTAContainer(
                <Button
                  onClick={handlePurchase}
                  loading={!finishedLoadingRoles()}
                  disabled={busyBuying || !canPurchase}
                  // color={publicKey ? "primary" : "accent"}
                  color={
                    !finishedLoadingRoles()
                      ? "accent"
                      : canPurchase
                      ? publicKey
                        ? "primary"
                        : "accent"
                      : "error"
                  }
                  className="text-white w-full mt-3"
                >
                  {!finishedLoadingRoles() ? (
                    "CHECKING ROLES"
                  ) : (
                    <>
                      {canPurchase ? (
                        <>{publicKey ? "PURCHASE TICKETS" : "CONNECT WALLET"}</>
                      ) : (
                        <>
                          <div className="text-red-700">
                            MISSING COMMUNITY ROLES
                          </div>
                        </>
                      )}
                    </>
                  )}
                </Button>
              )}

            {!isLive() && (
              <Button
                disabled
                color="accent"
                className="text-white w-full mt-3"
                style={{ color: "#FFF" }}
              >
                RAFFLE ENDED
              </Button>
            )}
          </>
        )}
      </div>
    </>
  );
};

const Completionist = () => (
  <span style={{ fontSize: "13px" }}>RAFFLE ENDED</span>
);

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
  if (completed) {
    // Render a complete state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <div className="time-left">
        <div className="items-container">
          <div className="item-container">
            <div className="item-value">{days}</div>
            <div className="item-title">D</div>
          </div>
          <div className="countdown-divider"></div>
          <div className="item-container">
            <div className="item-value">{hours}</div>
            <div className="item-title">H</div>
          </div>
          <div className="countdown-divider"></div>
          <div className="item-container">
            <div className="item-value">{minutes}</div>
            <div className="item-title">M</div>
          </div>
          <div className="countdown-divider"></div>
          <div className="item-container">
            <div className="item-value">{seconds}</div>
            <div className="item-title">S</div>
          </div>
        </div>
      </div>
    );
  }
};
