import "./ExploreRafflesPage.scss";
import { Drawer } from "react-daisyui";
import { useEffect, useState } from "react";
import { PageHeader, Button, Divider } from "antd";
import { GridLayout } from "../../Layout/GridLayout";
import { FilterFilled, CheckCircleOutlined } from "@ant-design/icons";
import { useFirebase } from "../../Auth/FirebaseService";
import firebase from "firebase";
import {
  RaffleItem,
  RaffleItemSkeleton,
} from "../../Compontents/RaffleItem/RaffleItem";
import { RadioGroup } from "@headlessui/react";
import moment from "moment";
import { Footer } from "../../Layout/Footer/Footer";
import { Tab } from "@headlessui/react";
function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const filters = [
  {
    name: "Live Raffle",
    desc: "Raffle by projects I follow",
    action: (
      query: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
    ) => query.where("status", "in", ["running", "drawing"]),
    orderBys: [
      {
        name: "Ending Soon",
        desc: "Raffles I've favorited",
        action: (
          query: firebase.firestore.Query<firebase.firestore.DocumentData>
        ) => query.orderBy("endDate", "asc"),
      },
      {
        name: "Ending Last",
        desc: "Raffles I've favorited",
        action: (
          query: firebase.firestore.Query<firebase.firestore.DocumentData>
        ) => query.orderBy("endDate", "desc"),
      },
    ],
  },
  {
    name: "All Raffle",
    desc: "Raffle by projects I follow",
    action: (
      query: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
    ) => query.where("status", "in", ["running", "drawing", "ended"]),
    orderBys: [
      {
        name: "New",
        desc: "Raffle by projects I follow",
        action: (
          query: firebase.firestore.Query<firebase.firestore.DocumentData>
        ) => query.orderBy("createDate", "desc"),
      },
      {
        name: "Old",
        desc: "Raffles I've favorited",
        action: (
          query: firebase.firestore.Query<firebase.firestore.DocumentData>
        ) => query.orderBy("createDate", "asc"),
      },
    ],
  },
  {
    name: "Ended",
    desc: "That were created recently",
    action: (
      query: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
    ) => query.where("status", "in", ["drawing", "ended"]),
    orderBys: [
      {
        name: "New",
        desc: "Raffle by projects I follow",
        action: (
          query: firebase.firestore.Query<firebase.firestore.DocumentData>
        ) => query.orderBy("createDate", "desc"),
      },
      {
        name: "Old",
        desc: "Raffles I've favorited",
        action: (
          query: firebase.firestore.Query<firebase.firestore.DocumentData>
        ) => query.orderBy("createDate", "asc"),
      },
    ],
  },
];

const raffleTypeOptions = [
  {
    label: "ALL",
    value: ["NFT", "WhiteList"],
  },
  {
    label: "NFT",
    value: ["NFT"],
  },
  {
    label: "WL",
    value: ["WhiteList"],
  },
];

export const ExploreRafflesPage = () => {
  const [visible, setVisible] = useState(false);
  const { firestore } = useFirebase();
  const [selected, setSelected] = useState(filters[0]);
  const [orderBy, setOrderBy] = useState(filters[0].orderBys[0]);
  const [raffleTypeFilter, setRaffleTypeFilter] = useState(0);

  const toggleVisible = () => {
    setVisible(!visible);
  };
  const [raffles, setRaffles] =
    useState<
      firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]
    >();

  useEffect(() => {
    // let query = firestore.collection(`ARaffle`);
    setRaffles(undefined);
    if (selected && raffleTypeFilter !== undefined) {
      let query = selected.action(firestore.collection(`ARaffle`));
      query = query.where("creatorType", "==", "project");
      if (orderBy) {
        query = orderBy.action(query);
      }
      query
        .get()
        .then(({ docs }) => {
          console.log(docs[0].data());
          docs = docs.filter((doc) =>
            raffleTypeOptions[raffleTypeFilter].value.includes(doc.data().type)
          );
          setRaffles([
            // {
            //   id: "ZqjHX5XSCYMIMcntn1Yp",
            //   data: () => {
            //     return raffleExample;
            //   },
            // } as any,
            ...docs,
          ]);
        })
        .catch((err) => {
          console.error("getRecentlyEndingSoonRaffles", err);
        });
    }

    // firestore
    //   .collection(`ARaffle`)
    //   // .where("status", "==", "running")
    //   .where("status", "in", ["running", "drawing", "ended"])
    //   .get()
    //   .then(({ docs }) => {
    //     setRaffles(docs);
    //   })
    //   .catch((err) => {
    //     console.error("getRecentlyEndingSoonRaffles", err);
    //   });
  }, [selected, orderBy, raffleTypeFilter]);

  const args: any = {};
  return (
    <Drawer
      {...args}
      open={visible}
      end
      onClickOverlay={toggleVisible}
      side={
        <div className=" bg-gray-200 w-72 px-4">
          <div
            style={{
              padding: "20px",
              fontWeight: 300,
              letterSpacing: "3px",
              color: "#4a8856",
              fontSize: "18px",
            }}
          >
            Raffle Type
          </div>

          <Tab.Group
            selectedIndex={raffleTypeFilter}
            onChange={(index: number) => setRaffleTypeFilter(index)}
          >
            <Tab.List className="flex space-x-1 rounded-xl bg-white p-1">
              {raffleTypeOptions.map(({ label, value }, index) => (
                <Tab
                  key={label}
                  value={value}
                  tabIndex={index}
                  className={({ selected }: any) =>
                    classNames(
                      "w-full rounded-lg py-2.5 text-sm font-medium leading-5 ",
                      "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none ",
                      selected
                        ? "bg-green-700 text-white shadow"
                        : " hover:bg-white/[0.12] "
                    )
                  }
                >
                  {label}
                </Tab>
              ))}
            </Tab.List>
          </Tab.Group>
          <Divider />
          <div
            style={{
              padding: "20px",
              fontWeight: 300,
              letterSpacing: "3px",
              color: "#4a8856",
              fontSize: "18px",
            }}
          >
            Filter by
          </div>
          <RadioGroup
            value={selected}
            onChange={(e: any) => {
              setSelected(e);
              setOrderBy(e.orderBys[0] || undefined);
            }}
          >
            <div className="space-y-2">
              {filters.map((plan) => (
                <RadioGroup.Option
                  key={plan.name}
                  value={plan}
                  className={({ active, checked }) =>
                    `${
                      active
                        ? "ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-300"
                        : ""
                    }
                  ${checked ? " bg-green-700  text-white" : "bg-white"}
                    relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
                  }
                >
                  {({ active, checked }) => (
                    <>
                      <div className="flex w-full items-center justify-between">
                        <div className="flex items-center">
                          <div className="text-sm">
                            <RadioGroup.Label
                              as="div"
                              className={`font-medium  ${
                                checked ? "text-white" : "text-gray-900"
                              }`}
                            >
                              {plan.name}
                            </RadioGroup.Label>
                            {/* <RadioGroup.Description
                              as="span"
                              className={`inline ${
                                checked ? "text-sky-100" : "text-gray-500"
                              }`}
                            >
                              <span>{plan.desc}</span>
                            </RadioGroup.Description> */}
                          </div>
                        </div>
                        {checked && (
                          <div className="shrink-0 text-white">
                            <CheckCircleOutlined className="h-6 w-6" />
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
          {selected?.orderBys?.length > 0 && (
            <>
              <Divider />
              <div
                style={{
                  padding: "20px",
                  fontWeight: 300,
                  letterSpacing: "3px",
                  color: "#4a8856",
                  fontSize: "18px",
                }}
              >
                Order by
              </div>
              <RadioGroup title="asds" value={orderBy} onChange={setOrderBy}>
                <RadioGroup.Label className="sr-only">
                  Server size
                </RadioGroup.Label>
                <div className="space-y-2">
                  {selected?.orderBys.map((plan) => (
                    <RadioGroup.Option
                      key={plan.name}
                      value={plan}
                      className={({ active, checked }) =>
                        `${
                          active
                            ? "ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-300"
                            : ""
                        }
                  ${checked ? " bg-green-700  text-white" : "bg-white"}
                    relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
                      }
                    >
                      {({ active, checked }) => (
                        <>
                          <div className="flex w-full items-center justify-between">
                            <div className="flex items-center">
                              <div className="text-sm">
                                <RadioGroup.Label
                                  as="div"
                                  className={`font-medium  ${
                                    checked ? "text-white" : "text-gray-900"
                                  }`}
                                >
                                  {plan.name}
                                </RadioGroup.Label>
                                {/* <RadioGroup.Description
                                  as="span"
                                  className={`inline ${
                                    checked ? "text-sky-100" : "text-gray-500"
                                  }`}
                                >
                                  <span>{plan.desc}</span>
                                </RadioGroup.Description> */}
                              </div>
                            </div>
                            {checked && (
                              <div className="shrink-0 text-white">
                                <CheckCircleOutlined className="h-6 w-6" />
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </>
          )}
        </div>
      }
    >
      <div className="sticky top-0 explore-container-header-container">
        <div className="lg:w-3/4 mx-auto  align-middle ">
          <PageHeader
            className="site-page-header-responsive"
            // onBack={() => window.history.back()}
            // onBack={() => navigate("/")}
            title={<div className="all-projects-title">RAFFLES</div>}
            extra={[
              // <div className="flex w-full component-preview p-4 items-center justify-center gap-2 font-sans">
              //   <Input
              //     size="large"
              //     prefix={<SearchOutlined />}
              //     placeholder="Find Raffle"
              //     {...args}
              //   />
              // </div>,
              // <Tooltip title="Filter">
              <Button
                onClick={toggleVisible}
                type="primary"
                shape="circle"
                size="large"
                icon={<FilterFilled />}
              />,
              // </Tooltip>,
            ]}
          ></PageHeader>
        </div>
      </div>
      <GridLayout>
        {raffles?.map((doc) => (
          <RaffleItem key={doc.id} raffleDoc={doc} layoutType="grid" />
        ))}
        {!raffles &&
          [1].map(() => {
            return <RaffleItemSkeleton key={1} layoutType="grid" />;
          })}
      </GridLayout>
      <div className="flex h-96 items-center justify-center"></div>
      <Footer />
    </Drawer>
  );
};

export const raffleExample = {
  lastUpdate: {
    seconds: 1661917880,
    nanoseconds: 632000000,
  },

  buyers: "holders",
  roles: [
    {
      id: "890330547346346044",
      name: "Team",
    },
  ],

  endDate: { toDate: () => moment().add(1, "days").toDate() },
  raffleId: "ZqjHX5XSCYMIMcntn1Yp",
  createDate: { toDate: () => moment().toDate() },
  winners: [],
  startDate: { toDate: () => moment().toDate() },
  profile: {
    name: "SOL Killer Bunnies",
    image:
      "https://firebasestorage.googleapis.com/v0/b/nft-anybodies.appspot.com/o/projects%2F7ZDY6KyqRM42cR3vgS2y%2Fimages%2Fimage-1660061555808?alt=media&token=a401e97a-bc05-412f-84d4-b04178b721fa",
  },
  projectId: "PXMd2IeOk6Odk8OcsxUpR7v95HU2",
  bookBurner: {
    ticketPrice: 0.05,
    path: "AVaultProject/PXMd2IeOk6Odk8OcsxUpR7v95HU2/AVault/7ZDY6KyqRM42cR3vgS2y/ABookBurner/ABookBurnerConfig",
    purchasedTickets: 1,
    walletAddress: "9qQ7kEZUiFqrFKpZmCvj4oi3SimeNHzC7PFeAkvHgGyZ",
  },
  token: {
    path: "AVaultProject/PXMd2IeOk6Odk8OcsxUpR7v95HU2/AToken/LekaHsA6Z3yMoZdgKIRI",
    name: "SKBC",
    decimalPoints: 1000000000,
    address: "PXWNGZXNUQfnaa5eKhmRW7rBrUKWTW86KwyFRnGMuwR",
  },
  numOfTickets: 500,
  notifications: {
    started: {
      discord: true,
    },
  },
  mintAddress: "5KnLviMm9oguugjenpQmdoZUGQgUKEnsDBZ64HuPctox",
  purchasedTickets: 167,
  type: "WhiteList",
  vaultId: "7ZDY6KyqRM42cR3vgS2y",
  status: "running",
  lockedTickets: 0,
  ownerWalletAddress: "4KyV2fjRTVzcHaksQ5jtT5wyfDzwv3MgRZpXBD1MsoYb",
  ticketPrice: 11,
  configWalletAddress: "ENYSuj8e1LnJqdVJ8MQjyWhsPqGUDSDzvUmee3T36UYZ",
  availableSpots: 10,
  metadata: {
    projectName: "Tay Kieth",
    description:
      "This exclusive streetwear collab offers a limited edition collectible, designed by Anybodies, and inspired by Tay Keith’s signature DOA brand. By minting & redeeming the physical sweater you'll get access to exclusive Tay Keith drops such as a 3D Avatar, Free tickets to live events (Drake etc), Tay Keith Beats and much more by tapping the NFC chip in the sweater.",
    twitter: "https://twitter.com/AnybodiesNFT",
    discordInviteLink: "https://discord.gg/EWCDXNAjUu",
    image:
      "https://www.arweave.net/jo2dIPTbnOPbJILCuwK5wkQQrvNjYa_jWDTV4DBfeto?ext=png",
    imageData: {
      ImageURL:
        "https://firebasestorage.googleapis.com/v0/b/nft-anybodies.appspot.com/o/projects%2FuM6rIJ0aYsO5dVajaeOB%2Fimages%2Fimage-1662032761179?alt=media&token=7588a239-f313-44d4-8cb5-e41f129dc87c",
      ImagePath: "projects/uM6rIJ0aYsO5dVajaeOB/images/image-1662032761179",
    },
    mintSupply: "1000",
    mintPrice: "3 SOL",
    mintDate: "12.12.2022",
    spots: 10,
    projectUtilities: `In collaboration with Anybodies, award winning music producer and fashion enthusiast Tay Keith launches into the metaverse on Magic Eden, to bring his #1 record and producer label Drumatized into the Solana ecosystem. Drumatized Over Anything = DOA.

    Anybodies uses a combination of NFT, NFC, AR and Blockchain technology to manufacture unique products & packaging that offer true physical to digital experiences, ownership and utility. The sweater is crafted by a top European manufacturer that works with the likes of Off-White and other exclusive brands.

    Details: 350 gsm, drop-shoulder design with ribbed neck-line, made from 80% cotton and styled with a low feel DOA signature print. To unlock the digital experience & airdrop, holders must interact with the NFC tag, built into the sweater with your smartphone device.`,
    projectRoadmap: `American record producer, grammy award nominee and best known for producing Sicko Mode, Non Stop, HOLIDAY and Jimmy Cooks. Tay made his first number one record while graduating in media management from Middle Tennessee State University, so it comes as no surprise that this talented artist, and his team, want to be a part of the metaverse and integrate his brand, Drumatized into the Web 3 community.`,
  },
};
