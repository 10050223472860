import "./PortfolioItem.scss";
import { Link } from "react-router-dom";
import { Statistic, Skeleton, Space, Divider } from "antd";
import firebase from "firebase";
import { useEffect, useState } from "react";
import { BookBurnerStat } from "../../Hooks/useBookBurner";
import { useIntersectionElement } from "../../Hooks/useIntersection";

type PortfolioItemProps = {
  type: "carousel" | "grid";
  stakingData: any;
};

const itemContainers = {
  carousel: "mx-4 flex align-middle items-center ",
  grid: "w-full sm:w-1/3 md:w-1/3 xl:w-1/5 p-4",
};

export const PortfolioItem: React.FC<PortfolioItemProps> = ({
  type,
  stakingData,
}) => {
  const [
    {
      ImageURL,
      VaultName,
      VaultRoute,
      stakedData: { staked, rewardMap },
    },
  ] = useState(stakingData);

  const [intersectionElement, isVisible] = useIntersectionElement("0px") as [
    JSX.Element,
    boolean
  ];

  const [inScreen, setInScreen] = useState(false);
  useEffect(() => {
    if (inScreen === false && isVisible === true) {
      setInScreen(isVisible);
    }
  }, [isVisible]);

  const openVault = () => {
    window
      .open(`https://www.diamondvaults.io/vault/${VaultRoute}`, "_black")
      ?.focus();
  };
  return (
    <div className={`${itemContainers[type]} fade-in`}>
      {intersectionElement}
      <div
        onClick={openVault}
        className="rounded-3xl w-full overflow-hidden shadow-lg hover:shadow-xl cursor-pointer m-auto max-w-xs my-3 bg-white"
      >
        <>
          <div className="w-full h-40 object-cover bg-gray-900">
            {ImageURL && (
              <img
                alt=""
                src={ImageURL}
                className=" w-full h-full object-cover"
              />
            )}
          </div>

          <div className="text-center px-3  py-2">
            <div className=" font-medium bold ">{VaultName}</div>
          </div>
          {Object.keys(rewardMap).map((key) => {
            return (
              <div key={key} className="flex flex-row w-full justify-evenly border-t pt-2">
                {[
                  {
                    title: "Token",
                    value: `$${rewardMap[key].TokenName}`,
                    show: true,
                  },
                  {
                    title: "Balance",
                    value: (+rewardMap[key].amount.toFixed(2)).toLocaleString(),
                    show: true,
                  },
                  {
                    title: "Daily",
                    value: (+rewardMap[key].dailyReward.toFixed(
                      2
                    )).toLocaleString(),
                    show: true,
                  },
                ].map(({ title, value, show }): any => (
                  <>
                    {show && (
                      <div key={JSON.stringify({ title, value, show })} className="flex flex-col">
                        <div className=" text-xs">{title}</div>
                        <div
                          style={{ color: "#62a770" }}
                          className=" text-black font-semibold"
                        >
                          {value.toLocaleString()}
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
            );
          })}

          <div className="text-center pb-3"></div>
        </>
      </div>
    </div>
  );
};

export const ProjectItemSkeleton = () => {
  return (
    <div className="mx-3 flex align-middle items-center profile-item-skeleton-container fade-in">
      <div className="rounded-3xl  overflow-hidden shadow-xl m-auto max-w-xs my-3 bg-white">
        <div className="text-center px-3 pb-7 pt-3">
          <Space>
            <Skeleton.Input size="small" active />
          </Space>
        </div>
        <div className="text-center px-3 pb-6 pt-0">
          <Space>
            <Skeleton.Button active />
          </Space>
        </div>

        {/* <div className="flex justify-center pb-0 ">
          <div className="text-center mr-3 border-r pr-3">
            <h2>34</h2>
            <span>Photos</span>
          </div>
          <div className="text-center">
            <h2>42</h2>
            <span>Friends</span>
          </div>
        </div> */}
      </div>
    </div>
  );
};
