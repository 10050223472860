import { message } from "antd";
import { createContext, useContext } from "react";
import { useFirebase } from "../../Auth/FirebaseService";

type ProcessPurchasePayload = {
  addPurchaseListener?: (requestPath: string) => Promise<void>;
};

const ProcessPurchaseContext = createContext<ProcessPurchasePayload>({});

export const useProcessPurchase = () => useContext(ProcessPurchaseContext);

export const ProcessPurchaseProvider = ({ children }: any) => {
  const { firestore } = useFirebase();
  const addPurchaseListener = async (requestPath: string) => {
    console.log(requestPath);
    const hide = message.loading("Busy confirming your transaction... ", 0);
    const sub = firestore.doc(requestPath).onSnapshot((snap) => {
      if (snap.exists) {
        const { status } = snap.data()!;
        if (status === "success") {
          hide();
          message.success("Your transaction was confirmed!", 2.5);
          sub();
        } else if (status === "failed") {
          hide();
          message.error("Failed to process your transactions", 2.5);
          sub();
        }
      }
    });
  };
  const value: ProcessPurchasePayload = {
    addPurchaseListener,
  };

  return (
    <ProcessPurchaseContext.Provider value={value}>
      {children}
    </ProcessPurchaseContext.Provider>
  );
};
