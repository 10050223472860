import { useEffect, useRef, useState } from "react";
import { getSolInWallet } from "../Web3/utils";
import firebase from "firebase";
import { useIntersection } from "./useIntersection";
import { Statistic } from "antd";

export const useBookBurner = (
  profile?: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
) => {
  const [loaded, setLoaded] = useState(false);
  const [sol, setSol] = useState(0);

  useEffect(() => {
    getSolana();
  }, [profile]);

  const getSolana = async () => {
    if (loaded === false && profile) {
      const sol = await getSolInWallet(profile.data().bookBurner);
      setSol(sol);
      setLoaded(true);
    }
  };

  return [loaded, sol];
};

export const BookBurnerStat = ({
  profile,
  margin
}: {
  margin:string;
  profile:
    | firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
    | firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
    | undefined;
}) => {
  const ref = useRef<any>();
  const isVisible = useIntersection(ref, "0px");
  const [inScreen, setInScreen] = useState(false);
  const [p, setP] = useState<
    | firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
    | firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
    | undefined
  >();
  const [bookBurnerLoaded, bookBurnerSol] = useBookBurner(p);

  useEffect(() => {
    if (inScreen === false) setInScreen(isVisible);
  }, [isVisible]);

  useEffect(() => {
    if (inScreen === true) {
      setP(profile);
    }
  }, [inScreen]);

  return (
    <div ref={ref}>
      <Statistic
        title="Book Burner"
        style={{width:'100px',margin}}
        value={bookBurnerSol as number}
        precision={3}
        loading={!bookBurnerLoaded as boolean}
        valueStyle={{ color: "#3f8600" }}
        prefix={
          <img
            className="w-3 h-3 object-contain "
            src="https://cryptologos.cc/logos/solana-sol-logo.svg?v=022"
            alt="avatar"
          />
        }
      />
    </div>
  );
};
