import "./CarouselLayout.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
    slidesToSlide: 2,
    // paritialVisibilityGutter: 60,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1400 },
    items: 4,
    slidesToSlide: 2,
    // paritialVisibilityGutter: 60,
  },
  tablet: {
    breakpoint: { max: 1400, min: 1000 },
    // paritialVisibilityGutter: 60,
    items: 4,
    slidesToSlide: 2,
  },
  tablet2: {
    breakpoint: { max: 1000, min: 464 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const CarouselLayout = ({ children }: any) => {
  return (
    <>
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={true}
        responsive={responsive}
        infinite={true}
        autoPlay

        // autoPlay={this.props.deviceType !== "mobile" ? true : false}
        autoPlaySpeed={4000}
        className="pb-10 w-full"
        keyBoardControl={true}
        // customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["mobile"]}
        // deviceType={""}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {children}
      </Carousel>
    </>
  );
};
