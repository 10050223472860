import { useWallet } from "@solana/wallet-adapter-react";
import React, { useEffect, useState } from "react";
import { useFirebase } from "../../../Auth/FirebaseService";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { Descriptions, List, Space, Spin } from "antd";
import { LikeOutlined, MessageOutlined, StarOutlined } from "@ant-design/icons";
import { GridLayout } from "../../../Layout/GridLayout";
import { PortfolioItem } from "../../../Compontents/PortfolioItem/PortfolioItem";

const IconText = ({ text }: { text: string }) => (
  <Space>
    {/* {React.createElement(icon)} */}
    {text}
  </Space>
);

export const VaultPortfolio = () => {
  const { publicKey } = useWallet();
  const { customFirebaseRequest } = useFirebase();
  const [loading, setLoading] = useState(false);
  const [protfolio, setProtfolio] = useState<any>();

  useEffect(() => {
    if (publicKey) {
      getStakerPortfolio();
    } else {
      setProtfolio(undefined);
    }
  }, [publicKey]);

  const getStakerPortfolio = async () => {
    if (publicKey) {
      setLoading(true);
      const rsp = await customFirebaseRequest(
        "V5_onGetStakersProtfolioByWallet",
        {
          walletAddress: publicKey.toString(),
        }
      );
      console.log(rsp);
      setProtfolio(rsp);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="m-auto w-full md:w-2/3 flex flex-col mb-16 items-center text-center justify-center">
        {/* <div className="w-full"> */}
        <p className="text-sm mt-2 text-gray-500 mb-2 w-full">
          Select your staking wallet
        </p>
        <div className="m-auto">
          <WalletMultiButton />
        </div>
      </div>
      {loading && (
        <div className="text-center">
          <Spin className="m-auto flex" />
        </div>
      )}
      {publicKey && (
        <GridLayout>
          {protfolio &&
            protfolio.map((item: any) => {
              return (
                <PortfolioItem
                  key={JSON.stringify(item)}
                  stakingData={item}
                  type="grid"
                />
              );
            })}
        </GridLayout>
      )}
    </>
  );
};
