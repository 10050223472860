import { Connection, LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";

export const privateRPC =
  "https://weathered-white-sunset.solana-mainnet.quiknode.pro/25f8d47456fd094f2a4bcead278e3683b7a7762f/";

export const privateConnection = new Connection(
  "https://weathered-white-sunset.solana-mainnet.quiknode.pro/25f8d47456fd094f2a4bcead278e3683b7a7762f/",
  "confirmed"
);

export const getSolInWallet = async (walletAddress: string) => {
  return (
    (await privateConnection.getBalance(new PublicKey(walletAddress))) /
    LAMPORTS_PER_SOL
  );
};
