import "./RaffleItem.scss";
import { CheckCircleTwoTone } from "@ant-design/icons";
// import { Button } from "antd";
import { Link, useSearchParams } from "react-router-dom";
import { Skeleton, Descriptions, Divider, Tag } from "antd";
import { Button } from "react-daisyui";
import firebase from "firebase";
import { forwardRef, useEffect, useRef, useState } from "react";
import Countdown from "react-countdown";
import moment from "moment";
import { useRaffleStatus } from "../../Hooks/useRaffleStatus";
import {
  useIntersection,
  useIntersectionElement,
} from "../../Hooks/useIntersection";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { firestore, useFirebase } from "../../Auth/FirebaseService";
import { TwitterTimelineEmbed } from "react-twitter-embed";
type RaffleItemProps = {
  layoutType: "carousel" | "grid";
  ref?: any;
  raffleDoc?: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>;
  raffleId?: string;
  autoLoad?: boolean;
};
const itemContainers = {
  carousel: "w-full p-4",
  grid: "w-full sm:w-1/3 md:w-1/3 xl:w-1/5 p-4",
};
export const RaffleItem: React.FC<RaffleItemProps> = ({
  layoutType,
  raffleDoc,
  raffleId,
  autoLoad,
}) => {
  const { firestore } = useFirebase();
  const [params, next] = useSearchParams();

  const [raffleSnap, setRaffleSnap] = useState<any>();
  const [
    {
      numOfTickets,
      token,
      ticketPrice,
      endDate,
      vaultId,
      bookBurner,
      profile,
      type,
      buyers,
      mintAddress,
      purchasedTickets,
      metadata,
      availableSpots,
    },
    setRaffleData,
  ] = useState<any>(raffleDoc?.data() || ({} as any));
  useEffect(() => {
    // getSnap();
  }, [raffleDoc, raffleId]);

  const getSnap = async () => {
    if (raffleDoc) {
      setRaffleSnap(raffleDoc);
      return setRaffleData(raffleDoc?.data());
    } else {
      const snap = await firestore.doc(`ARaffle/${raffleId}`).get();
      if (snap.exists) {
        setRaffleSnap(snap);
        return setRaffleData(snap?.data());
      }
    }
    return next({});
  };

  const getRaffleType = () => {
    if (type)
      switch (type) {
        case "NFT":
          return (
            <span
              style={{ fontSize: "8px" }}
              className="inline-block px-2 py-1 leading-none bg-orange-200 text-orange-800 rounded-full font-semibold uppercase tracking-wide"
            >
              NFT RAFFLE
            </span>
          );
        case "WhiteList":
          return (
            <span
              style={{ fontSize: "8px" }}
              className="inline-block px-2 py-1 leading-none bg-blue-200 text-blue-800 rounded-full font-semibold uppercase tracking-wide"
            >
              WL RAFFLE
            </span>
          );
      }
    return <></>;
  };

  const ref = useRef<any>();
  const [intersectionElement, isVisible] = useIntersectionElement("0px") as [
    JSX.Element,
    boolean
  ];
  const [inScreen, setInScreen] = useState(false);
  useEffect(() => {
    if (autoLoad && inScreen === false) {
      setInScreen(true);
      getSnap();
    } else if (inScreen === false && isVisible === true) {
      setInScreen(isVisible);
      getSnap();
    } else if (inScreen === false && autoLoad) {
      setInScreen(isVisible);
      getSnap();
    }
  }, [isVisible, autoLoad]);

  if (!profile || !raffleSnap)
    return (
      <>
        {intersectionElement}
        <RaffleItemSkeleton
          // ref={ref}
          layoutType={layoutType}
        ></RaffleItemSkeleton>
      </>
    );

  return (
    <div ref={ref} className={`${itemContainers[layoutType]} `}>
      {intersectionElement}
      <div className="c-card block bg-white shadow-sm hover:shadow-2xl rounded-lg overflow-hidden transition-all">
        <div className="relative overflow-hidden ">
          <img
            className=" inset-0 h-full w-full object-cover aspect-square "
            src={inScreen && metadata.image}
            alt=""
          />

          <div className="absolute top-0  w-full h-full raffler-profile-container">
            <Link to={`/projects/${vaultId}`}>
              <div className="flex items-center px-4 py-0 pt-3 align-middle justify-items-center whitespace-nowrap text-ellipsis">
                <img
                  className="w-6 h-6 rounded-full object-contain mr-1 shadow bg-white"
                  src={profile.image}
                  alt="avatar"
                />

                <h2 className="font-semibold text-sm text-white -mt-1 p-0 m-0">
                  <div className="flex align-middle items-center">
                    <CheckCircleTwoTone
                      style={{ fontSize: "10px" }}
                      twoToneColor="#52c41a"
                    />

                    <div className="ml-1 text-xs overflow-hidden whitespace-nowrap text-ellipsis">
                      {profile.name}
                    </div>
                  </div>
                  <p className="text-gray-700 opacity-70 p-0 m-0">
                    {getRaffleType()}
                  </p>
                </h2>
              </div>
            </Link>
          </div>
        </div>

        <div className="px-3">
          <div className="pt-2 grid text-center items-center">
            {buyers === "public" && (
              <Tag color="blue" style={{ margin: "0" }} className="m-0">
                PUBLIC RAFFLE
              </Tag>
            )}
            {buyers === "holders" && (
              <Tag color="orange" style={{ margin: "0" }} className="m-0">
                COMMUNITY RAFFLE
              </Tag>
            )}
          </div>
          {/* <p
            style={{ fontSize: "10px" }}
            className="mt-2 mb-0 font-bold text-start text-gray-600  overflow-hidden whitespace-nowrap text-ellipsis"
          >
            {type === "NFT"
              ? metadata?.collection?.family
              : metadata?.projectName}
          </p> */}

          {type === "WhiteList" && (
            <a href={`${metadata.twitter}`} target={"_blank"}>
              <div
                style={{ color: "#397c47" }}
                className="flex flex-row items-center gap-1 cursor-pointer  transition-all  font-bold mt-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3 w-3"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
                  />
                </svg>
                <div className=" font-semibold text-xs  overflow-hidden whitespace-nowrap text-ellipsis">
                  {availableSpots} WL Spots
                </div>
              </div>
            </a>
          )}
          {type === "NFT" && (
            <a
              href={`https://solscan.io/token/${mintAddress}`}
              target={"_blank"}
            >
              <div
                style={{ color: "#397c47" }}
                className="flex flex-row items-center gap-1 cursor-pointer  transition-all  font-bold mt-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3 w-3"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
                  />
                </svg>
                <div className=" font-semibold text-xs  overflow-hidden whitespace-nowrap text-ellipsis">
                  {metadata?.name}
                </div>
              </div>
            </a>
          )}
        </div>
        {/* <Divider style={{ margin: 5 }}></Divider> */}

        <div className="px-3  text-xs mt-2">
          <div className="text-10px">Tickets sold</div>
          <div className="flex flex-row items-center">
            <div className="font-bold " style={{ color: "#237233" }}>
              {(+purchasedTickets).toLocaleString()}
            </div>
            <div className=" text-xs">/{(+numOfTickets).toLocaleString()}</div>
          </div>
        </div>

        <div className="px-3  text-xs mt-2">
          <div className="text-10px">Price</div>
          <div className="flex flex-row items-center">
            <div className=" flex items-center  justify-between">
              <div className="mr-1">{(+ticketPrice).toLocaleString()}</div>
              &nbsp; <div className="  font-medium">${token.name} </div>
            </div>
            {bookBurner && (
              <>
                <div className=" opacity-25">
                  &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;
                </div>
                <div className=" flex items-center">
                  <div className="mr-1">
                    {(+bookBurner.ticketPrice).toLocaleString()}
                  </div>
                  &nbsp;<div className="  font-medium">$SOL</div>
                </div>
              </>
            )}
          </div>
        </div>
        {/* <div className="px-3  text-xs mt-5">
          <div className=" flex justify-between">
            <div className="text-gray-500 font-xs"> Tickets sold</div>
            <div className=" flex items-center">
              <div className="font-bold " style={{ color: "#237233" }}>
                {(+purchasedTickets).toLocaleString()}
              </div>
              <div className=" text-xs">
                /{(+numOfTickets).toLocaleString()}
              </div>
            </div>
          </div>

          <div className=" flex justify-between items-center  mt-3">
            <div className="text-gray-500 text-xs"> Ticket ($)</div>
            <div>
              <div className="font-bold text-xs" style={{ color: "#237233" }}>
                <div className=" flex items-center  justify-between">
                  <div className="mr-1">{(+ticketPrice).toLocaleString()}</div>
                  &nbsp; <div className="  font-medium">${token.name} </div>
                </div>
                {bookBurner && (
                  <div className="border-t flex items-center justify-between">
                    <div className="mr-1">
                      {(+bookBurner.ticketPrice).toLocaleString()}
                    </div>
                    &nbsp;<div className="  font-medium">$SOL</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div> */}
        <div className=" grid text-center items-center">
          <Countdown date={endDate.toDate()} renderer={renderer} />
        </div>
        <RaffleCTA raffleDoc={raffleSnap} next={next}></RaffleCTA>
      </div>
    </div>
  );
};

const RaffleCTA = ({ raffleDoc, next }: any) => {
  const raffleStatus = useRaffleStatus(raffleDoc);
  const getTheme = () => {
    switch (raffleStatus) {
      case "LIVE":
        return {
          bg: "bg-green-100",
          cta: "ENTER NOW",
          ctaColor: "primary",
        };
      case "ENDING":
        return {
          bg: "bg-orange-100",
          cta: "ENDING SOON",
          ctaColor: "warning",
        };
      case "DRAWING":
        return {
          bg: "bg-gray-100",
          cta: "DRAWING SOON",
          ctaColor: "accent",
        };
      case "ENDED":
        return {
          bg: "bg-gray-100",
          cta: "ENDED",
          ctaColor: "accent",
        };
      default:
      case "LOADING":
        return {
          bg: "bg-gray-100",
          cta: "",
          ctaColor: "accent",
        };
    }
  };

  return (
    <div
      className={`${
        getTheme().bg
      } px-4 pb-2 border-t border-b text-xs mt-2 text-gray-700 pt-2  text-center`}
    >
      <div className="p-2 grid text-center items-center text-sm text-white">
        <Button
          onClick={() => next({ raffle: raffleDoc!.id })}
          className="w-full"
          color={getTheme().ctaColor as any}
        >
          <div className="grid">
            <div style={{ color: "white" }}>{getTheme().cta}</div>
          </div>
        </Button>
      </div>
    </div>
  );
};

export const RaffleItemSkeleton: React.FC<RaffleItemProps> = ({
  layoutType,
  ref,
}) => {
  return (
    // <div className="w-full sm:w-1/3 md:w-1/3 xl:w-1/4 p-4">
    <div ref={ref} className={`${itemContainers[layoutType]} `}>
      {/* <div className="mx-3 flex align-middle items-center profile-item-skeleton-container"> */}
      <div className="rounded-3xl w-full overflow-hidden shadow-xl m-auto max-w-xs my-3 bg-white">
        {/* <div className="c-card block bg-white shadow-md hover:shadow-xl rounded-lg overflow-hidden"> */}
        <div className="relative overflow-hidden ">
          <div className="flex items-center align-middle text-center  h-full w-full object-cover aspect-square bg-gray-300">
            <div className="m-auto space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center">
              <div className="flex m-auto justify-center items-center w-full bg-gray-300 roundeddark:bg-gray-700">
                <svg
                  className="w-12 h-12 text-gray-200"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 640 512"
                >
                  <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                </svg>
              </div>
            </div>
          </div>
          <div className="absolute top-0  w-full h-full skeleton-raffler-profile-container">
            <div className="flex items-center px-4 py-0 pt-3 align-middle justify-items-center">
              {/* <img
                className="w-10 h-10 rounded-full object-contain mr-4 shadow"
                src="https://welcome.degods.com/images/degods_bitmap.svg"
                alt="avatar"
              /> */}
              <Skeleton.Avatar
                className="mr-4"
                active
                size={"large"}
                shape={"circle"}
              />
              <h2 className="font-semibold text-sm text-white -mt-1 p-0 m-0">
                <div className="flex align-middle items-center">
                  <Skeleton.Input active size={"small"} />
                </div>
              </h2>
            </div>
          </div>
          {/* <div className="absolute bottom-0 right-0 ">Yo</div> */}
        </div>
        <div className="px-3 pb-0 pt-4">
          <Skeleton></Skeleton>
        </div>
        <div className="px-4 pb-4 pt-3 border-t border-b text-xs text-gray-700 bg-gray-50  text-center">
          <Skeleton.Button active size={"large"} shape={"round"} block />
        </div>
        {/* <div className="p-4 border-t border-b text-xs text-gray-700 bg-green-100">
                <span className="flex items-center">
                  <i className="far fa-address-card fa-fw text-gray-900 mr-2"></i>{" "}
                  Ermäßigung mit Karte
                </span>
              </div> */}
      </div>
    </div>
  );
};

// const Completionist = () => (
//   <span style={{ fontSize: "13px" }}>RAFFLE ENDED</span>
// );

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
  if (completed) {
    // Render a complete state
    return <></>;
  } else {
    // Render a countdown
    return (
      <div className="time-left mt-4">
        {/* <Divider style={{ margin: 5 }}></Divider> */}

        <div className="items-container">
          <div className="item-container">
            <div className="item-value">{days}</div>
            <div className="item-title">D</div>
          </div>
          <div className="countdown-divider"></div>
          <div className="item-container">
            <div className="item-value">{hours}</div>
            <div className="item-title">H</div>
          </div>
          <div className="countdown-divider"></div>
          <div className="item-container">
            <div className="item-value">{minutes}</div>
            <div className="item-title">M</div>
          </div>
          <div className="countdown-divider"></div>
          <div className="item-container">
            <div className="item-value">{seconds}</div>
            <div className="item-title">S</div>
          </div>
        </div>
      </div>
    );
  }
};
